import React, { useEffect, useState } from "react";
import "../common-styles/GovernanceDirectors.scss";

import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import parse from "html-react-parser";

import directorimg1 from "../../images/our-company/governance/directorimg1.png";
import directorimg2 from "../../images/our-company/governance/directorimg2.png";

import RightArrowButton from "../../components/RightArrowButton/RightArrowButton";
import useGetDirectorsData from "../../hooks/useGetDirectorsData";
import { convertSlugtoString, investorImageUrl } from "../../helpers/utils";
import BoardOfDirectorBox from "../../components/BoardOfDirectorBox/BoardOfDirectorBox";
import Loader from "../../components/Loader/Loader";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const InvestorsDirectors = () => {
  const { isLoading, directorsData } = useGetDirectorsData(
    "/investors/policy/",
    "investor-directors"
  );
  const [slugSegmentsArr, setSlugSegmentsArr] = useState([]);

  useEffect(() => {
    setSlugSegmentsArr(window.location.pathname.split("/"));
    
  }, []);

  const boardOfDirectorsList =
    directorsData?.templateData.length > 0
      ? directorsData?.templateData.map(
          ({ name, slug, image, designation }, i) => (
            <BoardOfDirectorBox
              key={i}
              directorImg={investorImageUrl + image}
              directorName={name}
              directorDesignation={designation}
              linkTo={slug}
              // showKnowMore={
              //   slugSegmentsArr[4] === "senior-leaders" ? false : true
              // }
            />
          )
        )
      : null;

  if (isLoading) {
    return <Loader />;
  }
  return (
    <main>
      <MetaDecorator metaTitle="Welcome to Raymond | Investor" />
      {directorsData !== null && (
        <section className="directorssec1" data-aos="fade-in">
          <div className="my_container">
            <div className="text_container">
              <BreadCrumb
                linkTwoText="investors"
                linkTwoDisabled
                linkThreeText={convertSlugtoString(slugSegmentsArr[2])}
                linkThreeDisabled
                linkFourText={convertSlugtoString(slugSegmentsArr[3])}
                linkFour={`/investor/${slugSegmentsArr[2]}/${slugSegmentsArr[3]}`}
                linkFiveText={convertSlugtoString(slugSegmentsArr[4])}
                linkFiveDisabled
              />
              <h1 className="directors_heading">{directorsData?.title}</h1>
              <div className="govover_desc">
                {directorsData?.innerDescription
                  ? parse(directorsData?.innerDescription)
                  : null}
              </div>
            </div>
            <div className="directors_wrapper">
              <div className="directors_flex">{boardOfDirectorsList}</div>
            </div>
          </div>
        </section>
      )}
    </main>
  );
};
export default InvestorsDirectors;
