import { useQuery } from "react-query";
import { request } from "../utils/axios-utils";
// import newsimg1 from "../images/homepage/newsimg1.png";
// import buisnessimg1 from "../images/homepage/buisnessimg1.png";
// import buisnessimg2 from "../images/homepage/buisnessimg2.png";
import news1 from "../images/homepage/News and media (1).jpg";
import news2 from "../images/homepage/News and media (2).jpg";
import news3 from "../images/homepage/News and media (3).jpg";
import news4 from "../images/homepage/News and media (4).jpg";

const mediaImages = [news1, news2, news3, news4];

const fetchSliders = () => {
  return request({ url: `/sliders`, method: "get" });
};
const fetchOurBusiness = () => {
  return request({ url: `/businesses`, method: "get" });
};
const fetchOurbrands = () => {
  return request({ url: `/brands/categories`, method: "get" });
};
const fetchMediaData = () => {
  return request({ url: `/media?month=all`, method: "get" });
};
const fetchSusData = () => {
  return request({ url: `/sustainability`, method: "get" });
};
const fetchInvestorsMenu = () => {
  return request({ url: `/investors`, method: "get" });
};
export const useGetSliderData = () => {
  const { isLoading: isSliderLoading, data: sliderData } = useQuery(
    "sliders",
    fetchSliders
  );

  return {
    isSliderLoading,
    sliderData,
  };
};
export const useGetOurBusinessData = () => {
  const { isLoading: isOurBusinessLoading, data: businessData } = useQuery(
    "home-business",
    fetchOurBusiness
  );

  return {
    isOurBusinessLoading,
    businessData,
  };
};
export const useGetOurBrandsData = () => {
  const { isLoading: isOurBrandsLoading, data: brandsData } = useQuery(
    "home-brands",
    fetchOurbrands
  );

  return {
    isOurBrandsLoading,
    brandsData,
  };
};
export const useGetMediasData = () => {
  const { isLoading: isMediaLoading, data: mediaData } = useQuery(
    "home-media",
    fetchMediaData,
    {
      select: (data) => {
        let dataArr = data.data.data;
        let mediaImgIndex = -1;
        if (dataArr.length > 4) {
          dataArr = dataArr.slice(0, 4);
        }

        dataArr = dataArr.map((media) => {
          mediaImgIndex += 1;
          return { ...media, bgImage: mediaImages[mediaImgIndex] };
        });

        return dataArr;
      },
    }
  );
  return {
    isMediaLoading,
    mediaData,
  };
};
export const useGetSusData = () => {
  const { isLoading: isSusLoading, data: susDataArr } = useQuery(
    "home-sus",
    fetchSusData,
    {
      select: (data) => {
        let susData = data.data.data;
        let categoryName = "csr";
        susData = susData.filter((susDetail) => {
          const categoryString = susDetail.category.toLowerCase();
          if (categoryString.includes(categoryName)) {
            return susDetail;
          }
        });
        return susData;
      },
    }
  );
  return {
    isSusLoading,
    susDataArr,
  };
};
export const useGetInvestorsData = () => {
  const { isLoading: isInvestorsMenuLoading, data: investorsMenuData } =
    useQuery("menu-investors", fetchInvestorsMenu, {
      select: (data) => {
        return data.data.data;
      },
    });

  return {
    isInvestorsMenuLoading,
    investorsMenuData,
  };
};
export const useGetMenuSusData = () => {
  const { isLoading: isMenuSusDataLoading, data: susMenuData } = useQuery(
    "menu-sus",
    fetchSusData,
    {
      select: (data) => {
        return data.data.data;
      },
    }
  );

  return {
    isMenuSusDataLoading,
    susMenuData,
  };
};
