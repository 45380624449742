import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

//react-query
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "react-toastify/dist/ReactToastify.css";
import Routing from "./Routing";
import AOS from "aos";
import "aos/dist/aos.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/bundle";
//custom styles
import "./common.scss";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      disable: function () {
        var maxWidth = 1279;
        return window.innerWidth < maxWidth;
      },
      // once: true,
    });
  }, []);
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="*" element={<Routing />} />
          </Routes>
        </BrowserRouter>
        {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> */}
      </QueryClientProvider>
    </>
  );
};

export default App;
