import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import {
  ctaLeftArrowRed,
  ctaLeftArrowWhite,
  ctaLeftArrowBlack,
} from "../../images";

const LeftArrowButton = ({
  normalCta,
  anchorCta,
  anchorLink,
  linkTo,
  ctaText,
  ctaWhite,
}) => {
  const [mouseEnter, setMouseEnter] = useState(false);

  if (normalCta) {
    return (
      <span
        className={`${
          ctaWhite
            ? "left_arrow_cta_flex cta_white"
            : "left_arrow_cta_flex apply_hover"
        }`}
        onMouseEnter={() => setMouseEnter(true)}
        onMouseLeave={() => setMouseEnter(false)}
      >
        {ctaWhite ? (
          <img
            src={ctaLeftArrowWhite}
            alt="Button arrow design"
            className="cta_left_arrow"
          />
        ) : (
          <img
            src={mouseEnter ? ctaLeftArrowBlack : ctaLeftArrowRed}
            alt="Button arrow design"
            className="cta_left_arrow"
          />
        )}
        <span className="cta_text">{ctaText}</span>
      </span>
    );
  }

  if (anchorCta) {
    return (
      <a
        className={`${
          ctaWhite
            ? "left_arrow_cta_flex cta_white"
            : "left_arrow_cta_flex apply_hover"
        }`}
        href={anchorLink}
        target="_blank"
        onMouseEnter={() => setMouseEnter(true)}
        onMouseLeave={() => setMouseEnter(false)}
      >
        {ctaWhite ? (
          <img
            src={ctaLeftArrowWhite}
            alt="Button arrow design"
            className="cta_left_arrow"
          />
        ) : (
          <img
            src={mouseEnter ? ctaLeftArrowBlack : ctaLeftArrowRed}
            alt="Button arrow design"
            className="cta_left_arrow"
          />
        )}
        <span className="cta_text">{ctaText}</span>
      </a>
    );
  }
  return (
    <Link
      className={`${
        ctaWhite
          ? "left_arrow_cta_flex cta_white"
          : "left_arrow_cta_flex apply_hover"
      }`}
      to={linkTo}
      onMouseEnter={() => setMouseEnter(true)}
      onMouseLeave={() => setMouseEnter(false)}
    >
      {ctaWhite ? (
        <img
          src={ctaLeftArrowWhite}
          alt="Button arrow design"
          className="cta_left_arrow"
        />
      ) : (
        <img
          src={mouseEnter ? ctaLeftArrowBlack : ctaLeftArrowRed}
          alt="Button arrow design"
          className="cta_left_arrow"
        />
      )}
      <span className="cta_text">{ctaText}</span>
    </Link>
  );
};

export default LeftArrowButton;
