import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { request } from "../../../utils/axios-utils";
import moment from "moment";
import "../../common-styles/Governance.scss";
import "../../common-styles/MediaListing.scss";

//components
import RightArrowButton from "../../../components/RightArrowButton/RightArrowButton";
import InnerPageBanner from "../../../components/InnerPageBanner/InnerPageBanner";

//images
import { blackdropdownarrow, searchIcon } from "../../../images";
import { ReactComponent as NewsRedLogo } from "../../../images/our-company/governance/news-red-logo.svg";
import { ReactComponent as NewsWhiteLogo } from "../../../images/our-company/governance/news-white-logo.svg";
import { ReactComponent as YearDropDown } from "../../../images/general/year-drop-down.svg";
import bannerimg from "../../../images/general/newroombanner.jpg";
import Loader from "../../../components/Loader/Loader";
import { homepageurl } from "../../../helpers/constant-words";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";
import _ from "lodash";
const listingData = [
  {
    topicName: "Code Of Business",
    isLink: true,
    linkTo: "/newsroom/news-and-media/code-of-business",
  },
  {
    topicName: "Business For Peace",
    isLink: true,
    linkTo: "/newsroom/news-and-media/board-of-directos/directors",
  },
  {
    topicName: "Corporate Social Responsiblity Policy",
    isLink: true,
    linkTo: "/newsroom/news-and-media/compliance-report/reports",
  },
  {
    topicName: "Preservation Of Documents & Archival Policy",
    isLink: false,
    anchorLink: "https://www.youtube.com/watch?v=WKKg7B6B0s8",
  },
  {
    topicName:
      "Details Of Familiarization Program Imparted To Independent Directors",
    isLink: true,
    linkTo: homepageurl,
  },
  {
    topicName:
      "Policy On Inquiry Under SEBI (Prohibition Of Insider Trading) Regulations, 2015",
    isLink: false,
    anchorLink: "https://www.youtube.com/watch?v=WKKg7B6B0s8",
  },
  {
    topicName: "Nomination & Numeration Policy",
    isLink: false,
    anchorLink: "https://www.youtube.com/watch?v=WKKg7B6B0s8",
  },
];
const fetchMediaReports = (searchParam, sortYearVal,sortMonthVal, offsetVal) => {
  let url = `/media`;

  if (sortYearVal) {
    url += `?year=${sortYearVal}`;
  }
  if (sortMonthVal) {
    url += `&month=${sortMonthVal}`;
  }

  if (searchParam.trim()) {
    url += `&search=${searchParam}`;
  }

  if (offsetVal) {
    url += `&offset=${offsetVal}`;
  }

  return request({ url: url, method: "get" });
};

const fetchMediaReportYears = () => {
  return request({ url: "/media/years-month", method: "get" });
};

const MediaReportsListing = () => {
  const [mediaReportsArr, setMediaReportsArr] = useState([]);
  const [months, setMonths] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [sortYearVal, setSortYearVal] = useState("");
  const [sortMonthVal, setSortMonthVal] = useState("all");
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [offsetVal, setOffsetVal] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);

  const { isLoading, refetch: refetchMediaReports } = useQuery(
    ["news-and-media", searchParam, sortYearVal,sortMonthVal, offsetVal],
    () => fetchMediaReports(searchParam, sortYearVal,sortMonthVal, offsetVal),
    {
      enabled: false,
      onSuccess: (data) => {
        if (offsetVal) {
          setMediaReportsArr((prevState) => {
            return [...prevState, ...data.data.data];
          });
        } else {
          setMediaReportsArr(data.data.data);
        }

        if (data.data.data.length < 12) {
          setShowLoadMore(false);
        } else {
          setShowLoadMore(true);
        }

       
        setPageLoading(false);
      },
    }
  );
  const { data: mediaReportYearsData, refetch: refetchMediaReportsYears } =
    useQuery("media-report-years", () => fetchMediaReportYears(), {
      enabled: false,
      onSuccess: (res) => {
       
        setSortYearVal(res.data.data[0].year);
        setMonths(res.data.data[0].months);
      },
    });

  useEffect(() => {
    refetchMediaReports();
    refetchMediaReportsYears();
  }, []);

  useEffect(() => {
    if (sortYearVal) {
      refetchMediaReports();
    }
  }, [sortYearVal,sortMonthVal]);

  useEffect(() => {
    if (offsetVal) {
      refetchMediaReports();
    }
  }, [offsetVal]);

  useEffect(() => {
    // if (searchParam) {
    refetchMediaReports();
    // }
  }, [searchParam]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    refetchMediaReports();
  };
  const handleLoadMoreClick = () => {
    setOffsetVal((prevState) => {
      return prevState + 12;
    });
  };

  const yearsList = mediaReportYearsData?.data?.data.map((item, i) => (
    <option value={item.year} key={i}>
      {item.year}
    </option>
  ));
  const monthsList = months?.map((item, i) => (
    <option value={item.value} key={i}>
      {item.label}
    </option>
  ));

  const govList =
    mediaReportsArr.length > 0 ? (
      mediaReportsArr.map(
        ({ publisher, date, name, description, template, slug, url }, i) => (
          <li className="col-lg-3 col-md-6 gov_listing_item" key={i}>
            <div className="news_logos_wrapper">
              <NewsRedLogo className="newslogo newslogored" />
              <NewsWhiteLogo className="newslogo newslogowhite" />
            </div>
            <div className="media_cate_date_wrapper">
              <span className="media_category">{publisher}</span>
              <span className="media_date">
                &nbsp;|&nbsp;{moment(date).format("MMM DD, YYYY")}
              </span>
            </div>
            <h4 className="gov_listing_topic_name">{name}</h4>
            {/* <p className="media_desc">{description}</p> */}
            <div className="btn_wrapper">
              {template !== "external" ? (
                <RightArrowButton ctaText="read more" linkTo={slug} />
              ) : (
                <RightArrowButton
                  ctaText="read more"
                  anchorCta
                  anchorLink={url}
                />
              )}
            </div>
          </li>
        )
      )
    ) : (
      <h3 className="err_message">No news and media found</h3>
    );

  if (pageLoading) {
    return <Loader />;
  }

  return (
    <main>
      <MetaDecorator metaTitle="Welcome to Raymond | Media" />
      <section className="govsec1" data-aos="fade-in">
        <InnerPageBanner
          bannerHeading="news and media"
          bannerDesc="Catch up on the latest product updates, news coverage, campaigns, initiatives and more…"
          bannerImg={bannerimg}
          linkTwoText="NewsRoom"
          linkTwoDisabled
          linkThreeText="news and media"
          linkThreeDisabled
        />
      </section>
      <section className="govsec2 media_listing_sec2">
        <div className="my_container">
          <div className="search_sort_flex search_sort_news">
            <form className="search_form_flex" onSubmit={handleSearchSubmit}>
              <input
                type="text"
                className="search_text"
                role="search"
                placeholder="Type Your Words Here"
                value={searchParam}
                onChange={(e) => {
                  setSortMonthVal("all");

                  setSearchParam(e.target.value);
                  setOffsetVal(0);
                }}
              />
              <button type="submit" className="search_btn">
                <img
                  src={searchIcon}
                  alt="Search icon"
                  className="search_icon"
                />
              </button>
            </form>
            <div className="sort_news">
            <div className="sort_flex_1">
              <span className="year_text year_text_news_1">year</span>
              <select
                className="year_dropdown year_news"
                value={sortYearVal}
                onChange={(e) => {
                  setSearchParam("");
                  setSortYearVal(e.target.value);
                  setSortMonthVal("all");
                  
                  setMonths(_.find(mediaReportYearsData?.data?.data,{year:e.target.value})?.months);
                  setOffsetVal(0);
                }}
              >
                {yearsList}
              </select>
              <img src={blackdropdownarrow} className="downarrow_news" />
              {/* <YearDropDown className="yeardropdownimg" /> */}
            </div>
            <div className="sort_flex">
              <span className="year_text year_text_news">month</span>
              <select
                className="year_dropdown year_news"
                value={sortMonthVal}
                onChange={(e) => {
                  setSearchParam("");
                  setSortMonthVal(e.target.value);
                  setOffsetVal(0);
                }}
              >
                <option value="all">
      All
    </option>
                {monthsList}
              </select>
                <img src={blackdropdownarrow} className="downarrow_news" />
              {/* <YearDropDown className="yeardropdownimg" /> */}
            </div>
          </div>
        </div>
        </div>
      </section>

      <section className="govsec3 media_sec3">
        <div className="my_container">
          <ul className="row custom-li">{govList}</ul>
        </div>
      </section>
      {showLoadMore && (
        <div className="load_more_wrapper">
          <span className="load_more_text" onClick={handleLoadMoreClick}>
            {isLoading ? "Loading..." : "Load more"}
          </span>
        </div>
      )}
    </main>
  );
};

export default MediaReportsListing;
