export const Data = [
  {
    question: "Apply Now",
    answer:
      "When you join Raymond, you don’t just start with a job. You become a part of something bigger! Because we believe your career is all about being what you want to be. It’s about fuelling your curiosity, honing your skills and bringing your best self to work. And what’s best than being a part of a diversified business, a network of people who inspire you, give you the freedom to explore and innovate, and an environment where you can thrive?<br /><br />As a rapidly expanding company, we’re always looking for talented, passionate and creative individuals to join our team. Begin your journey towards a fulfilling career with us.",
    cta: "https://raymondcareers.peoplestrong.com/",
    ctaName: "Apply Now",
  },
  {
    question: "Beware Of Fraudulent Recruitment Practices",
    answer:
      "Raymond's recruitment team will never ask for any payment, in any form or name like ‘recruitment fees’, ‘processing fees’, ‘security deposits’ etc. or any money in exchange for offer letters or interview calls.<br /><br />Any person dealing with unauthorised parties for seeking job opportunities at Raymond Ltd. in lieu of money will be doing so at their own risk. Raymond will not have any obligation to honour the terms of any fake offer letter issued or provide employment to anyone who has been issued a fraudulent offer letter. Further, Raymond will not be responsible for any losses (monetary or otherwise) including, but not limited to, theft of data incurred as a consequence.<br /><br />Kindly note that Raymond Limited (and/or its group companies) follows a formal recruitment process through its HR department and does not outsource the final selection of prospective employees to any individuals or agencies.",
    cta: "",
    ctaName: "",
  },
  {
    question: "How To Spot A Fake Offer Letter?",
    answer:
      "They can demand a fee instead of a job offer or /interview opportunity with Raymond Ltd. and request your personal information like bank account details, PINs, etc.<br />They may give an interview location, invalid telephone phone numbers, or sample offer letters along with e-mail.",
    cta: "",
    ctaName: "",
  },
  {
    question: "How To Identify Recruitment Fraud?",
    answer:
      "The person or entity solicited you, without you having applied for any job offers. Offer-related communications sent from email ID other than the official Raymond Ltd. email id i.e fraudulent job offers often ask for financial or personal information early in the process.<br /><br />Raymond does not ask for any financial commitment or contribution from a candidate at any stage of the recruitment process.",
    cta: "",
    ctaName: "",
  },
  {
    question: "What Should You Do If You Receive Such Fake Offers?",
    answer:
      "If you suspect a fake offer letter, <strong>do not respond and send money</strong>, treat the email with caution.<br />If you think you have received a job offer from Raymond Ltd, contact us at mail Id.",
    cta: "",
    ctaName: "",
  },
];
