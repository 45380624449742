import React, { useEffect, useState } from "react";

import "../../common-styles/GovernanceOverview.scss";
import { useQuery } from "react-query";
import { request } from "../../../utils/axios-utils";
import { getLastSegment } from "../../../helpers/utils";

import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import parse from "html-react-parser";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";
const fetchOverview = (lastSegment) => {
  return request({ url: `/investors/policy/${lastSegment}`, method: "get" });
};

const GovernanceOverview = () => {
  const [lastSegment, setLastSegment] = useState("");
  const [overviewData, setOverviewData] = useState(null);

  const { isLoading, refetch: refetchOverview } = useQuery(
    ["governance-overview-content", lastSegment],
    () => fetchOverview(lastSegment),
    {
      enabled: false,
      onSuccess: (data) => {
        if (data.data.data) {
          setOverviewData(data.data.data);
        }
      },
    }
  );
  useEffect(() => {
    if (lastSegment) {
      refetchOverview();
    }
  }, [lastSegment]);
  useEffect(() => {
    setLastSegment(getLastSegment(window.location.pathname));
  }, []);
 
  if (isLoading) {
    return <Loader />;
  }

  return (
    <main>
    <MetaDecorator metaTitle="Welcome to Raymond | Leaders" />
      {overviewData && (
        <section className="govoversec1" data-aos="fade-in">
          <div className="my_container">
            <BreadCrumb
              linkTwoText="our company"
              linkTwoDisabled
              linkThreeText={overviewData.investorCategory.title}
              linkThree={`/our-company/${overviewData.investorCategory.slug}`}
              linkFourText={overviewData.name}
              linkFourDisabled
            />
            <h1 className="govover_heading">{overviewData.title}</h1>
            <p className="govover_subheading">
              {overviewData.innerDescription
                ? parse(overviewData.innerDescription)
                : null}
            </p>
            <div className="govover_desc">
              {overviewData?.templateData?.content
                ? parse(overviewData?.templateData?.content)
                : null}
            </div>
          </div>
        </section>
      )}
    </main>
  );
};

export default GovernanceOverview;
