import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { request } from "../utils/axios-utils";
import { getLastSegment } from "../helpers/utils";

const useGetDirectorsData = (apiPath, queryKeyName) => {
  const [lastSegment, setLastSegment] = useState("");

  const [directorsData, setDirectorsData] = useState(null);

  const fetchDirectors = (lastSegment) => {
    return request({ url: `${apiPath}${lastSegment}`, method: "get" });
  };

  const { isLoading, refetch: refetchDirectors } = useQuery(
    [queryKeyName, lastSegment],
    () => fetchDirectors(lastSegment),
    {
      // cacheTime: 0,
      enabled: false,
      onSuccess: (data) => {
        if (data.data.data) {
          setDirectorsData(data.data.data);
        }
      },
    }
  );

  useEffect(() => {
    if (lastSegment) {
      refetchDirectors();
    }
  }, [lastSegment]);

  useEffect(() => {
    setLastSegment(getLastSegment(window.location.pathname));
  }, []);

  return { isLoading, directorsData };
};

export default useGetDirectorsData;
