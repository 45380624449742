import React, { useEffect } from "react";
import parse from "html-react-parser";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import "./lifestyle.scss";
import { Fade } from "react-awesome-reveal";
import {
  apparel,
  garmenting,
  lifestylebanner,
  raymondhome,
  raymondmadetomeasure,
  retail,
  shirting,
  suiting,
} from "../../images";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";
const Lifestyle = () => {
  const { width } = useWindowDimensions();

  const bannerArr = [
    {
      id: 1,
      img: lifestylebanner,
      firsthead: "HOME | OUR BUSINESSES | LIFESTYLE",
      heading: "Lifestyle",
      desc: "The Raymond Group is involved in a variety of businesses and has a portfolio of well-known brands in Lifestyle category.",
    },
  ];
  const brandArr = [
    {
      id: 1,
      img: suiting,
      heading: "Suiting",
      desc: "Raymond is one of the largest vertically and horizontally integrated manufacturers of worsted suiting fabric in the world and Raymond is a market leaders in the domestic worsted suiting industry in India. Raymond’s state-of-the- art fabric manufacturing plants in Vapi (Gujarat), Chhindwara (Madhya Pradesh) and Jalgaon (Maharashtra) have an aggregate manufacturing capacity of ~43 million meters of suiting fabric extending across all wool, poly- wool, silk and other premium blends. Marketed under the brand ‘Raymond Fine Fabrics’, it is undoubtedly amongst the most preferred brands in the Textile sector.",
    },
    {
      id: 2,
      img: shirting,
      heading: "Shirting",
      desc: "Raymond manufactures some of the finest shirting fabrics in India, marked by innovative designs and aligned to latest fashion trends. With a clear domain in crafting natural fabrics in India, Raymond’s B2B shirting Business has an edge in creating the best cotton and linen fabrics. Having the prowess of manufacturing 340s count cotton and 150 lea linen, Raymond is the leading supplier to domestic and international brands both for high value cotton and linen fabrics along with bottom weight fabrics.<br /><br />Raymond’s B2C Shirting fabrics business was launched in 2015 and has grown rapidly sine. Raymond has emerged as the largest over-the-counter (OTC) branded fabric player in the organized shirting segment and commands a strong brand preference in the category. The shirting fabric brand is marketed under the name ‘Raymond Fine Fabrics’.",
    },
    {
      id: 3,
      img: garmenting,
      heading: "Garmenting",
      desc: "Raymond’s garmenting unit is now a preferred choice and enjoys critical supplier status for leading international brands. The business vertical has come a long way and has been a constant partner for its customers all through the pandemic. With our par excellence production facilities offering products in menswear, such as suits, formal blazers, jackets, formal trousers, denims and shirts, we are a clothier to the world.",
    },
    {
      id: 4,
      img: raymondmadetomeasure,
      heading: "Raymond Made to Measure",
      desc: "Raymond Made to Measure is a unique service of premium clothing from the house of Raymond - The Complete Man. This luxurious service allows our discerning customer to personalize their ensemble bringing together their own personal taste. Take a pick from our fabric and contemporize it with an extension of your choice. From suits to formal jackets and shirts to an entire outfit; customize it to what suits your inner style. <br /><br />Just like an exquisite craft, Made to Measure is the labour of love between art and technology.",
    },
    {
      id: 5,
      img: retail,
      heading: "Retail",
      desc: "Having been reckoned as the pioneer in organised textile retail, Raymond presented the first ‘The Raymond Shop’ (TRS) in 1958 to the Indian discerning consumers and offered them the unique fabric-to-garment service through in-house tailoring value proposition. Raymond continued its retail journey by establishing a significant network through TRS, MTM and EBOs for its in-house brands namely Raymond Ready to Wear (RRTW), Park Avenue, ColorPlus and Parx across the length and breadth of the country. The Company is now strengthening a new brand called ‘Ethnix by Raymond’ and is offering more diverse product lines.<br /><br />Marking its foray in the ecommerce space, Raymond launched <a href='https://www.myraymond.com/home' target='_blank'>www.myraymond.com</a> - a one-stop fashion solution for all brands under the Raymond umbrella.",
    },
    {
      id: 6,
      img: raymondhome,
      heading: "Raymond Home",
      desc: "Raymond Home is India’s leading Home Textile Brand today that brings versatile Home textiles collection that brings a subtle yet stylish vibe into one’s home décor as well as kitchen living and dining accessories for its customers across the country. <br /><br />Combining various patterns and natural themes, Raymond Home offers a host of aprons, napkins, table linen, diwan sets and readymade curtains. Wide selection of bed sheet sets, towels, blankets, comforters, quilts and bath robes are available in distinct colours and patterns, thus providing ample choice to customers to customize and mix and match to create a unique look for their home.",
    },
    {
      id: 7,
      img: apparel,
      heading: "Apparel",
      desc: "Raymond Group is one of the leading branded apparel players in the menswear industry comprising a portfolio of four Power Brands – Raymond Ready-to- wear (RRTW), Park Avenue (PA), Color Plus (CP) and Parx. All four brands command a significant share of wardrobe solutions in the market. Raymond has also forayed into ethnic wear segment through the launch of ‘ethnix’ brand.<br /><br />The brands enjoy a high consumer recall and acceptance on account of enduring brand trust and product quality. The Raymond brand enjoys considerable leadership over most competing brands, translating into iconic status.",
    },
  ];

  const bannerList = bannerArr.map((item, i) => (
    <div className="row" key={i}>
      <div className="col-lg-8">
        {width <= 834 ? (
          <div className="text_container">
            <div className="breadcrumb">{item.firsthead}</div>
            <div className="heading">{item.heading}</div>
            <div className="desc">{item.desc}</div>
          </div>
        ) : null}
        <img
          src={item.img}
          alt="raymond business img"
          className="mainimg"
          loading="lazy"
        />
      </div>
      <div className="col-lg-4 flex_box">
        {width > 834 ? (
          <div className="text_container">
            <div className="breadcrumb">{item.firsthead}</div>
            <div className="heading">{item.heading}</div>
            <div className="desc">{item.desc}</div>
          </div>
        ) : null}
      </div>
    </div>
  ));

  const brandList =
    brandArr.length > 0 ? (
      brandArr.map((brand, i) => (
        <div
          className="col-lg-6 flex_box"
          key={i}
          data-aos="fade-in"
          id={brand.id}
        >
          {width <= 834 ? (
            <div className="mobheading">{brand.heading}</div>
          ) : null}
          <div className="zoomout">
            <img
              src={brand.img}
              alt="raymond business img"
              className="mainimg"
              width={1544}
              height={1074}
            />
          </div>
          <div className="text_container">
            {width > 834 ? (
              <div className="heading">{brand.heading}</div>
            ) : null}
            <div className="desc">{parse(brand.desc)}</div>
          </div>
        </div>
      ))
    ) : (
      <h3 className="err_message">No Business data found</h3>
    );

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
    <MetaDecorator metaTitle="Welcome to Raymond | Lifestyle" />
      <section className="lifestylebusiness_sec1" data-aos="fade-in">
        <div className="my_container">{bannerList}</div>
      </section>

      <section className="lifestylebusiness_sec2 extra_space_issue" data-aos="fade-up">
        <div className="my_container">
          <div className="row">{brandList}</div>
        </div>
      </section>
    </>
  );
};

export default Lifestyle;
