import React from "react";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { faqban, faqbanmob, termsbanmob } from "../../images";
import Accordion from "./Accordion";
import "./faq.scss";

const FAQ = () => {
  const { width } = useWindowDimensions();

  const bannerArr = [
    {
      id: 1,
      img: faqban,
      mobimg: faqbanmob,
      firsthead: "Home | faq",
      desc: 'Got a question? We are here to answer. If you don’t find your question here, drop us a mail at <a href="mailto:consumercarecell@raymond.in">consumercarecell@raymond.in</a>',
    },
  ];

  const bannerList = bannerArr.map((item, i) => (
    <>
      <div className="text_container">
        <div className="breadcrumb">{item.firsthead}</div>
        <div className="heading">Frequently Asked Questions</div>
        <div
          className="desc"
          dangerouslySetInnerHTML={{ __html: item.desc }}
        ></div>
      </div>
      {width > 600 ? (
        <img
          src={item.img}
          alt="raymond Brand img"
          className="mainimg"
          loading="lazy"
          width="100%"
          height="100%"
        />
      ) : (
        <img
          src={item.mobimg}
          alt="raymond Brand img"
          className="mainimg"
          loading="lazy"
          width="100%"
          height="100%"
        />
      )}
    </>
  ));

  return (
    <>
      <MetaDecorator metaTitle="Welcome to Raymond | FAQ" />
      <section className="faq_sec1" data-aos="fade-in">
        <div className="my_container">{bannerList}</div>
      </section>

      <section className="faq_sec2" data-aos="fade-in">
        <div className="my_container">
          <Accordion />
        </div>
      </section>
    </>
  );
};

export default FAQ;
