import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import "./ourreach.scss";
import { useQuery } from "react-query";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

import { dropdownarrow, loader, ourreachban } from "../../../images";
import { request } from "../../../utils/axios-utils";
import RightArrowButton from "../../../components/RightArrowButton/RightArrowButton";
import companyData from "./companyData.json";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";

const fetchLocationsCategories = () => {
  return request({ url: "/locations/categories", method: "get" });
};

const OurReach = () => {
  const { width } = useWindowDimensions();
  const [ourReachIndex, setOurReachIndex] = useState(null);

  const {
    isLoading: isLocationsCategoriesLoading,
    data: locationCategoriesData,
  } = useQuery("locations-categories", fetchLocationsCategories);

  const {
    isLoading: isCategoryLocationsLoading,
    data: categoryLocationsData,
    refetch: refetchCategoryLocations,
  } = useQuery(
    "category-location",
    async () => {
      return request({ url: `/locations/${ourReachIndex}`, method: "get" });
    },
    {
      enabled: false,
    }
  );
  useEffect(() => {
    if (locationCategoriesData) {
      const locationCategoriesArr = locationCategoriesData.data.data;
      if (locationCategoriesArr.length > 0) {
        setOurReachIndex(locationCategoriesArr[0]["_id"]);
      }
    }
  }, [locationCategoriesData]);

  useEffect(() => {
    if (ourReachIndex) {
      refetchCategoryLocations();
    }
  }, [ourReachIndex]);

  const bannerArr = [
    {
      id: 1,
      img: ourreachban,
      firsthead: "Our Company | Our Reach",
      heading: "Our Reach",
      desc: "Raymond’s global footprint includes exports to 55+ countries, stores across different locations and unique experiences.",
    },
  ];

  // const ourReachData = [
  //   {
  //     tabName: "Plants",
  //     list: [
  //       {
  //         name: "JAYKAYORG AG",
  //         address: "2, Quai Ostervald, 2000 Nechatel, Switzerland",
  //       },
  //       {
  //         name: "RAYMOND (EUROPE) LIMITED",
  //         address: "Barratt House, 341-349, Oxford Street, London – WIC2JE",
  //       },
  //       {
  //         name: "R&A LOGISTICS INC.",
  //         address: "27, Mulvaney Street , Asheville, NC 28803, USA",
  //       },
  //       {
  //         name: "RAYMOND LIFESTYLE",
  //         address:
  //           "International Dubai Multi Commodities Centre ”DMCC”, Dubai UAE",
  //       },
  //       {
  //         name: "SILVER SPARK - MIDDLE EAST FZE",
  //         address: "SAIF Zone, Sharjah, UAE",
  //       },
  //       {
  //         name: "SILVER SPARK APPAREL - ETHIOPIA PLC",
  //         address:
  //           "Shade No. 17, 18 and 19, Hawassa Industrial Park, Hawassa, Ethiopia",
  //       },
  //     ],
  //   },
  //   {
  //     tabName: "Dummy",
  //     list: [
  //       {
  //         name: "Dummy",
  //         address: "2, Quai Ostervald, 2000 Nechatel, Switzerland",
  //       },
  //       {
  //         name: "RAYMOND (EUROPE) LIMITED",
  //         address: "Barratt House, 341-349, Oxford Street, London – WIC2JE",
  //       },
  //       {
  //         name: "R&A LOGISTICS INC.",
  //         address: "27, Mulvaney Street , Asheville, NC 28803, USA",
  //       },
  //       {
  //         name: "RAYMOND LIFESTYLE",
  //         address:
  //           "International Dubai Multi Commodities Centre ”DMCC”, Dubai UAE",
  //       },
  //       {
  //         name: "SILVER SPARK - MIDDLE EAST FZE",
  //         address: "SAIF Zone, Sharjah, UAE",
  //       },
  //       // {
  //       //   name: "SILVER SPARK APPAREL - ETHIOPIA PLC",
  //       //   address:
  //       //     "Shade No. 17, 18 and 19, Hawassa Industrial Park, Hawassa, Ethiopia",
  //       // },
  //     ],
  //   },
  // ];

  const bannerList = bannerArr.map((item, i) => (
    <div className="row" key={i}>
      <div className="col-lg-6 flex_box">
        {width > 834 ? (
          <div className="text_container">
            <BreadCrumb linkTwoText={item.firsthead} linkTwoDisabled />
            <div className="heading">{item.heading}</div>
            <div className="desc">{item.desc}</div>
            <div className="store_cta_wrapper">
              <RightArrowButton
                anchorCta
                anchorLink="https://stores.myraymond.com/"
                ctaText="Store Locator"
              />
            </div>
          </div>
        ) : null}
      </div>
      <div className="col-lg-6">
        {width <= 834 ? (
          <div className="text_container">
            <BreadCrumb linkTwoText={item.firsthead} linkTwoDisabled />
            <div className="heading">{item.heading}</div>
            <div className="desc">{item.desc}</div>
            <div className="store_cta_wrapper">
              <RightArrowButton
                anchorCta
                anchorLink="https://stores.myraymond.com/"
                ctaText="Store Locator"
              />
            </div>
          </div>
        ) : null}
        <img
          src={item.img}
          alt="raymond Brand img"
          className="mainimg"
          loading="lazy"
          width={807}
          height={539}
        />
      </div>
    </div>
  ));

  const companyList = companyData.map((item, i) => (
    <div className="col-md-6 col-lg-4 our_company" key={i}>
      <div className="flex_box">
        <h4 className="title">{item.title}</h4>
        <RightArrowButton anchorCta anchorLink={item.link} ctaText="Here" />
      </div>
    </div>
  ));

  const ourReachTabList = locationCategoriesData?.data?.data?.map(
    ({ name, _id }) => (
      <option value="Raymond" key={_id} data-reachindexid={_id}>
        {name}
      </option>
    )
  );

  const ourReachDataList = categoryLocationsData?.data?.data?.map(
    ({ name, address, _id }) => (
      <div className={`col-md-6 col-lg-4 our_reach active`} key={_id}>
        <div className="flex_box">
          <h4 className="title">{name}</h4>
          <p className="desc">{address}</p>
        </div>
      </div>
    )
  );

  const handleReachTabChange = (e) => {
    const reachIndexId = e.target.selectedOptions[0].dataset.reachindexid;
    setOurReachIndex(reachIndexId);
  };

  if (isLocationsCategoriesLoading) {
    return <Loader />;
  }
  return (
    <>
    <MetaDecorator metaTitle="Welcome to Raymond | Our Reach" />
      <section className="reach_sec1" data-aos="fade-in">
        <div className="my_container">
          {bannerList}

          <div className="company_wrapper">
            <div className="row">{companyList}</div>
          </div>
        </div>
      </section>

      <section className="reach_sec2" data-aos="fade-in">
        <div className="my_container">
          <div className="dropdown_sec">
            <div className="reach_tabs_dropdown_wrapper">
              <select
                onChange={handleReachTabChange}
                className="reach_dropdown"
              >
                {ourReachTabList}
              </select>

              <img
                src={dropdownarrow}
                alt="raymond dropdown logo"
                className="downdropdownarrow"
              />
            </div>
          </div>
          <div className="our_reach_wrapper row">
            {isCategoryLocationsLoading ? (
              <figure className="locations_data_loader">
                <img src={loader} alt="Loader" />
              </figure>
            ) : (
              ourReachDataList
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default OurReach;
/*{
 <div
      className={`our_reach row ${ourReachIndex == i ? "active" : ""}`}
      key={i}
    >
      {reachData.list.map(({ name, address }, i) => (
        <div className="col-md-6 col-lg-4 flex_box" key={i}>
          <h4 className="title">{name}</h4>
          <p className="desc">{address}</p>
        </div>
      ))}
    </div> 
}
*/
