import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import {
  ctaRightArrowRed,
  ctaRightArrowWhite,
  ctaRightArrowBlack,
} from "../../images";

const RightArrowButton = ({
  normalCta,
  anchorCta,
  anchorLink,
  linkTo,
  ctaText,
  ctaWhite,
  target = "_blank",
}) => {
  const [mouseEnter, setMouseEnter] = useState(false);
  if (normalCta) {
    return (
      <span
        className={`${
          ctaWhite
            ? "right_arrow_cta_flex cta_white"
            : "right_arrow_cta_flex apply_hover"
        }`}
        onMouseEnter={() => setMouseEnter(true)}
        onMouseLeave={() => setMouseEnter(false)}
      >
        <span className="cta_text">{ctaText}</span>
        {ctaWhite ? (
          <img
            src={ctaRightArrowWhite}
            alt="Button arrow design"
            className="cta_right_arrow"
          />
        ) : (
          <img
            src={mouseEnter ? ctaRightArrowBlack : ctaRightArrowRed}
            alt="Button arrow design"
            className="cta_right_arrow"
          />
        )}
      </span>
    );
  }

  if (anchorCta) {
    return (
      <a
        className={`${
          ctaWhite
            ? "right_arrow_cta_flex cta_white"
            : "right_arrow_cta_flex apply_hover"
        }`}
        onMouseEnter={() => setMouseEnter(true)}
        onMouseLeave={() => setMouseEnter(false)}
        href={anchorLink}
        target={target}
      >
        <span className="cta_text">{ctaText}</span>
        {ctaWhite ? (
          <img
            src={ctaRightArrowWhite}
            alt="Button arrow design"
            className="cta_right_arrow"
          />
        ) : (
          <img
            src={mouseEnter ? ctaRightArrowBlack : ctaRightArrowRed}
            alt="Button arrow design"
            className="cta_right_arrow"
          />
        )}
      </a>
    );
  }
  return (
    <Link
      className={`${
        ctaWhite
          ? "right_arrow_cta_flex cta_white"
          : "right_arrow_cta_flex apply_hover"
      }`}
      to={linkTo}
      onMouseEnter={() => setMouseEnter(true)}
      onMouseLeave={() => setMouseEnter(false)}
    >
      <span className="cta_text">{ctaText}</span>
      {ctaWhite ? (
        <img
          src={ctaRightArrowWhite}
          alt="Button arrow design"
          className="cta_right_arrow"
        />
      ) : (
        <img
          src={mouseEnter ? ctaRightArrowBlack : ctaRightArrowRed}
          alt="Button arrow design"
          className="cta_right_arrow"
        />
      )}
    </Link>
  );
};

export default RightArrowButton;
