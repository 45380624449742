import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

import "../common-styles/GovernanceComplianceReport.scss";
import { useQuery } from "react-query";
import { request } from "../../utils/axios-utils";
import moment from "moment";
import Loader from "../../components/Loader/Loader";
import {
  convertSlugtoString,
  getLastSegment,
  investorImageUrl,
} from "../../helpers/utils";

import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import _, { filter } from "lodash";
import { ReactComponent as ReportDownload } from "../../images/general/report-download.svg";
import { blackdropdownarrow, dropdownarrow } from "../../images";
const fetchComplianeReports = (lastSegment) => {
  return request({ url: `/investors/policy/${lastSegment}`, method: "get" });
};

const InvestorsComplianceReport = () => {
  const [lastSegment, setLastSegment] = useState("");
  const [slugSegmentsArr, setSlugSegmentsArr] = useState([]);
  const [reportsData, setReportsData] = useState(null);
  const [reports, setreports] = useState([]);
  const [isFilterVisible, setisFilterVisible] = useState(false);
  const [year, setyear] = useState(null);
  const [years, setyears] = useState([]);
  const [quarter, setquarter] = useState(null);

  const { isLoading, refetch: refetchComplianeReports } = useQuery(
    ["media-compliance-reports", lastSegment],
    () => fetchComplianeReports(lastSegment),
    {
      enabled: false,
      onSuccess: (data) => {
        const result = data.data.data;

  
        if (result) {
          setReportsData(result);
        }

        if (result.is_year_filter_visible) {
          setisFilterVisible(true);

          // setyears(_.map(result.templateData, 'year'));
          setyears(_.uniq(
            result.templateData
              .map((data) => {
                if (data.year) {
                  return data.year;
                }
              })
              .filter(Boolean)
          )
          );
          

          setyear(result.templateData[0].year);
          setreports(
            _.filter(result.templateData, {
              year: result.templateData[0].year,
            })
          );
        } else {
          setreports(result.templateData);
        }

      
      },
    }
  );

  useEffect(() => {
    if (lastSegment) {
      refetchComplianeReports();
    }
  }, [lastSegment]);

  useEffect(() => {
    if (year || quarter) {
     

      let filters = {
        year: parseInt(year),
      };

      if (quarter && quarter != "all") {
        filters.quarter = quarter;
      }

    
      setreports(_.filter(reportsData.templateData, filters));
    }
  }, [year, quarter]);

  useEffect(() => {
    setSlugSegmentsArr(window.location.pathname.split("/"));
    setLastSegment(getLastSegment(window.location.pathname));
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <main>
      {reportsData !== null && (
        <section className="compsec1">
          <div className="my_container">
            <BreadCrumb
              linkTwoText="investors"
              linkTwoDisabled
              linkThreeText={convertSlugtoString(slugSegmentsArr[2])}
              linkThreeDisabled
              linkFourText={convertSlugtoString(slugSegmentsArr[3])}
              linkFour={`/investor/${slugSegmentsArr[2]}/${slugSegmentsArr[3]}`}
              linkFiveText={convertSlugtoString(slugSegmentsArr[4])}
              linkFiveDisabled
            />
            <h1 className="govover_heading">{reportsData.name}</h1>
            <p className="govover_subheading">
              {reportsData.innerDescription
                ? parse(reportsData.innerDescription)
                : null}
            </p>
            {/* <div className="row">
              <div className="col-md-6">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setyear(e.target.value)}
                >
                  {years.map((year) => {
                    return <option value={year}>{year}</option>;
                  })}
                </select>
              </div>
              <div className="col-md-6">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setquarter(e.target.value)}
                >
                  <option value="all">All</option>

                  <option value="Q1">Q1</option>
                  <option value="Q2">Q2</option>
                  <option value="Q3">Q3</option>
                  <option value="Q4">Q4</option>
                </select>
              </div>
            </div> */}
            {/* Year and quarter selection dropdown by front end dev */}
            {isFilterVisible&& <div className="compliance_year_flex">
              <div className="compliance_dropdown_wrapper">
                <select
                  className="compliance_dropdown"
                  onChange={(e) => setyear(e.target.value)}
                >
                  {years.map((year) => {
                    return <option value={year}>{`${year} - ${++year}`}</option>;
                  })}
                </select>
                <img
                  src={blackdropdownarrow}
                  alt="raymond dropdown logo"
                  className="downdropdownarrow"
                />
              </div>
              
             {reportsData?.is_quarter_filter_visible && <div className="compliance_dropdown_wrapper">
                <select
                  class="compliance_dropdown"
                  onChange={(e) => setquarter(e.target.value)}
                >
                  <option value="all">All</option>

                  <option value="Q1">Q1</option>
                  <option value="Q2">Q2</option>
                  <option value="Q3">Q3</option>
                  <option value="Q4">Q4</option>
                </select>
                <img
                  src={blackdropdownarrow}
                  alt="raymond dropdown logo"
                  className="downdropdownarrow"
                />
              </div>}
            </div>}
            {reports.length ? (
              <div className="report_table_wrapper">
                <table className="report_table">
                  <tbody>
                    <tr className="report_table_row">
                      <th className="report_title_col report_title_heading_col">
                        report
                      </th>
                      {/* <th className="date_col date_heading_col">date</th> */}
                      <th className="download_logo_wrapper download_logo_heading_wrapper">
                        file
                      </th>
                    </tr>
                    {reports.map((report, i) => (
                      <tr className="report_table_row" key={i}>
                        <td className="report_title_col">
                          {report.title}
                          {/* <br />{" "}
                        <span className="mb_show_date">
                          {moment(report.date).format("MMMM, YYYY")}
                        </span> */}
                        </td>
                        {/* <td className="date_col">
                        {moment(report.date).format("MMMM, YYYY")}
                      </td> */}
                        <td className="download_logo_wrapper">
                          <a
                            href={investorImageUrl + report.file}
                            target="_blank"
                          >
                            <ReportDownload className="report_download_logo" />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <h1>No Data Found</h1>
            )}
          </div>
        </section>
      )}
    </main>
  );
};

export default InvestorsComplianceReport;
