import React, { useState, useEffect } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  contactpic,
  partnerban,
  partnerbanmob,
  dropddownicon,
  ctaRightArrowBlack,
  ctaRightArrowRed,
  choose1,
  choose2,
  choose3,
  choose4,
} from "../../images";
import { ToastContainer, toast } from "react-toastify";

import RightArrowButton from "../../components/RightArrowButton/RightArrowButton";
import "./partnerwithus.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import LeftArrowButton from "../../components/LeftArrowButton/LeftArrowButton";

import http from "../../helpers/http";
import { cleanPhoneCode } from "../../helpers/utils";
import config from "../../config/config";
import Joi from "joi";

import { useNavigate } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const PartnerWithUs = () => {
  const { width } = useWindowDimensions();
  const [mouseEnter, setMouseEnter] = useState(false);

  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [codes, setCodes] = useState([]);
  const [countryCode, setcountryCode] = useState("+91");

  let base_url = config.api_url + "/forms/partner";

  const navigate = useNavigate();

  const schema = Joi.object({
    name: Joi.string().trim().required().label("Name"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    phone: Joi.number().required().label("Phone"),

    detail: Joi.string().trim().required().label("Detail"),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {


    e.preventDefault();
   
    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    const { data } = await http.post(base_url, {
      ...values,
      phone: `${countryCode}${values.phone}`,
    });

    if (data) {
      // redirect
      navigate("/thank-you");
      // props.history.push("/thank-you");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const fetchCodes = async () => {
    const { data } = await http.get(
      `${config.api_url}/general/countries/codes`
    );
    
    if (data) {
      setCodes(data);
    }
  };

  useEffect(() => {
    fetchCodes();
  }, []);

  const bannerArr = [
    {
      id: 1,
      img: partnerban,
      firsthead: "Partner with us.",
      desc: "We, at Raymond, believe in joining hands with the right partners to collaborate with us in our endeavour to offer the best to our customers.",
    },
  ];

  const partnerArr = [
    {
      icon: choose1,
      desc: "Being one of the largest fashion retail brands across the globe, our extensive network enhances the impression of greater financial viability, performance and market influence.",
    },
    {
      icon: choose2,
      desc: "With over nine decades of combined experience and goodwill across various sectors, we have built a reputation for the generations to maintain.",
    },
    {
      icon: choose3,
      desc: "Ensuring uncompromised quality and consistency across product lines by partaking in innovation, automation and sustainability.",
    },
    {
      icon: choose4,
      desc: "Raymond is rapidly integrating new-age technologies to stay ahead of the curve. While our manufacturing facilities are equipped with state-of-the-art infrastructure, we are continuously enhancing our digital capabilities to support our core strengths.",
    },
  ];

  const bannerList = bannerArr.map((item, i) => (
    <>
      <div className="text_container">
        <BreadCrumb linkTwoText={item.firsthead} linkTwoDisabled />
        <div className="heading">Partner with us.</div>
        <div className="desc">{item.desc}</div>
      </div>
      <img
        src={item.img}
        alt="raymond img"
        className="mainimg"
        loading="lazy"
        width="1601"
        height="500"
      />
    </>
  ));

  const partnerList = partnerArr.map((item, i) => (
    <div className="col-lg-3 flex_box">
      <img
        src={item.icon}
        className="eclipse"
        alt="eclipse icon"
        width="100%"
        height="100%"
        loading="lazy"
      />
      <p className="desc">{item.desc}</p>
    </div>
  ));

  const mbpartnerList = partnerArr.map((item, i) => (
    <SwiperSlide key={i}>
      <div className="flex_box">
        <img
          src={item.icon}
          className="eclipse"
          alt="eclipse icon"
          width="100%"
          height="100%"
          loading="lazy"
        />
        <p className="desc">{item.desc}</p>
      </div>
    </SwiperSlide>
  ));
  return (
    <>
    <MetaDecorator metaTitle="Welcome to Raymond | Partner With Us" />
      <section className="partner_sec1" data-aos="fade-in">
        <div className="my_container">{bannerList}</div>
      </section>

      <section className="partner_sec2" data-aos="fade-in">
        <div className="my_container">
          <div className="text_container">
            <h4 className="breadcrumb">Partnership</h4>
            <h3 className="heading">Know More On Associating With Raymond:</h3>
          </div>
          {width > 834 ? (
            <div className="row">{partnerList}</div>
          ) : (
            <>
              <Swiper
                modules={[Navigation]}
                loop="true"
                navigation={{
                  prevEl: ".partner_left",
                  nextEl: ".partner_right",
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                  },
                }}
                className="mySwiper"
              >
                {mbpartnerList}
              </Swiper>
              <div className="button_flex">
                <div className="partner_left">
                  <LeftArrowButton normalCta ctaText="previous" />
                </div>
                <div className="partner_right">
                  <RightArrowButton normalCta ctaText="next" />
                </div>
              </div>
            </>
          )}
        </div>
      </section>

      <section
        className="partner_sec3"
        data-aos="fade-in"
        onSubmit={handleSubmit}
      >
        <div className="my_container">
          <div className="row">
            <div className="col-lg-7">
              <div className="text_container">
                <div className="breadcrumb">Inquire Now</div>
                <h4 className="heading">Get In Touch</h4>
                <p className="desc">
                  Now is the time to partner with us to boost and empower your
                  business prospects.
                </p>
              </div>
              <div className="form_container">
                <form className="form-group">
                  <div class="material-textfield">
                    <input
                      placeholder=" "
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                    <label>Name*</label>
                  </div>
                  <div className="error">{formError.name}</div>
                  <div class="contact_email">
                    <div class="material-textfield contactus">
                      <div class="mobile_no_flex">
                        <div class="cc_box">
                          <select
                            class="cc_dd"
                            name="countryCode"
                            id="countryCode"
                            value={countryCode}
                            onChange={(e) => setcountryCode(e.target.value)}
                          >
                            {codes.map((code, i) => {
                              return (
                                <option
                                  value={`+${cleanPhoneCode(code)}`}
                                  key={i}
                                >
                                  {`+${cleanPhoneCode(code)}`}
                                </option>
                              );
                            })}
                          </select>
                          <img
                            className="dropwdown_icon"
                            width={9}
                            height={8}
                            loading="lazy"
                            src={dropddownicon}
                            alt="raymond dropwdown img"
                          />
                        </div>
                        <div class="no_box">
                          <input
                            placeholder=" "
                            name="phone"
                            type="tel"
                            value={values.phone}
                            onChange={handleChange}
                          />
                          <label>Phone Number*</label>
                        </div>
                      </div>
                      <div className="error">{formError.phone}</div>
                    </div>
                    <div class="material-textfield emailid">
                      <input
                        placeholder=" "
                        type="text"
                        role="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      <label>E-mail ID*</label>
                      <div className="error">{formError.email}</div>
                    </div>
                  </div>

                  {/* <div class="material-textfield">
                    <select
                      className="input select_padding"
                      name="category"
                      value={values.category}
                      onChange={handleChange}
                    >
                      <option value="">Select Category*</option>
                      <option value="demo1" className="demo1 active">
                        Demo1
                      </option>
                      <option value="demo2">Demo2</option>
                      <option value="demo3">Demo3</option>
                    </select>
                    <div className="error">{formError.category}</div>
                  </div> */}
                  <div class="material-textfield">
                    <input
                      placeholder=" "
                      type="text"
                      name="detail"
                      value={values.detail}
                      onChange={handleChange}
                    />
                    <label>Enquiry Details*</label>
                    <div className="error">{formError.detail}</div>
                  </div>
                  <div className="cta_wrapper">
                    <button type="submit">
                      <span
                        className="right_arrow_cta_flex apply_hover"
                        onMouseEnter={() => setMouseEnter(true)}
                        onMouseLeave={() => setMouseEnter(false)}
                      >
                        <span className="cta_text">Submit</span>

                        <img
                          src={
                            mouseEnter ? ctaRightArrowBlack : ctaRightArrowRed
                          }
                          alt="Button arrow design"
                          className="cta_right_arrow"
                        />
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="zoomout">
                <img
                  className="contact_img"
                  alt="contact img"
                  src={contactpic}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default PartnerWithUs;
