import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import "../common-styles/GovernanceOverview.scss";
import parse from "html-react-parser";

import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import { request } from "../../utils/axios-utils";
import Loader from "../../components/Loader/Loader";
import {
  convertSlugtoString,
  getLastSegment,
  investorImageUrl,
} from "../../helpers/utils";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const fetchOverview = (lastSegment) => {
  return request({ url: `/investors/policy/${lastSegment}`, method: "get" });
};

const InvestorsOverview = () => {
  const [lastSegment, setLastSegment] = useState("");
  const [overviewData, setOverviewData] = useState(null);
  const [slugSegmentsArr, setSlugSegmentsArr] = useState([]);

  const { isLoading, refetch: refetchOverview } = useQuery(
    ["investor-overview-content", lastSegment],
    () => fetchOverview(lastSegment),
    {
      enabled: false,
      onSuccess: (data) => {
        if (data.data.data) {
          setOverviewData(data.data.data);
        }
      },
    }
  );
  useEffect(() => {
    if (lastSegment) {
      refetchOverview();
    }
  }, [lastSegment]);

  useEffect(() => {
    setSlugSegmentsArr(window.location.pathname.split("/"));
    setLastSegment(getLastSegment(window.location.pathname));
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <main>
      <MetaDecorator metaTitle="Welcome to Raymond | Investor" />
      {overviewData !== null && (
        <section className="govoversec1" data-aos="fade-in">
          <div className="my_container">
            <BreadCrumb
              linkTwoText="investors"
              linkTwoDisabled
              linkThreeText={convertSlugtoString(slugSegmentsArr[2])}
              linkThreeDisabled
              linkFourText={convertSlugtoString(slugSegmentsArr[3])}
              linkFour={`/investor/${slugSegmentsArr[2]}/${slugSegmentsArr[3]}`}
              linkFiveText={convertSlugtoString(slugSegmentsArr[4])}
              linkFiveDisabled
            />
            <h1 className="govover_heading">{overviewData.title}</h1>
            <p className="govover_subheading">
              {overviewData?.innerDescription
                ? parse(overviewData?.innerDescription)
                : null}
            </p>
            <div className="govover_desc">
              {overviewData?.templateData
                ? parse(overviewData?.templateData?.content)
                : null}
            </div>
            {overviewData?.templateData ? (
              overviewData?.templateData?.image ? (
                <img
                  src={investorImageUrl + overviewData?.templateData?.image}
                  alt=""
                  className="img-fluid"
                />
              ) : null
            ) : null}
          </div>
        </section>
      )}
    </main>
  );
};

export default InvestorsOverview;
