import React, { useEffect, useState } from "react";
import "./LeadershipInterviewInner.scss";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";

//components
import InnerPageBanner from "../../../components/InnerPageBanner/InnerPageBanner";
import { quoteComma } from "../../../images";
import Loader from "../../../components/Loader/Loader";
import { request } from "../../../utils/axios-utils";
import { interviewImageUrl } from "../../../helpers/utils";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";

const LeadershipInterviewInner = () => {
  const [interviewDataObj, setInterviewDataObj] = useState(null);
  const { leadershipInterviewsInner } = useParams();

  const {
    isLoading,
    data: interviewData,
    refetch: refetchInterviewData,
  } = useQuery(
    "interview-inner",
    async () => {
      return request({
        url: `/interviews/${leadershipInterviewsInner}`,
        method: "get",
      });
    },
    {
      enabled: false,
    }
  );
  useEffect(() => {
    if (leadershipInterviewsInner) {
      refetchInterviewData();
    }
  }, [leadershipInterviewsInner]);

  useEffect(() => {
    if (interviewData) {
      const {
        data: { data },
      } = interviewData;
      if (data.length > 0) {
        setInterviewDataObj(data[0]);
      }
    }
  }, [interviewData]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <main>
    <MetaDecorator metaTitle="Welcome to Raymond | Leadership Interview" />
      {interviewDataObj ? (
        <>
          <section className="lead_inner_sec1" data-aos="fade-in">
            <InnerPageBanner
              bannerHeading={interviewDataObj.title}
              bannerDesc={parse(interviewDataObj.description)}
              bannerImg={interviewImageUrl + interviewDataObj.image}
              linkTwoText="our company"
              linkTwoDisabled
              linkThreeText="Leaders"
              linkThree="/our-company/senior-leaders"
              linkFourText={interviewDataObj.slug}
              linkFourDisabled
            />
          </section>

          <section className="lead_inner_sec2" data-aos="fade-in">
            <div className="my_container">
              <img src={quoteComma} alt="Quote logo" className="quote_logo" />
              <p className="quote_desc">{parse(interviewDataObj.quote)}</p>
              <iframe
                src={interviewDataObj.youtube_link}
                title="News & Media"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="interview_video_iframe"
              ></iframe>
            </div>
          </section>

          <section className="lead_inner_sec3">
            <div className="my_container">
              <p className="lead_inner_desc">
                {parse(interviewDataObj.content)}
              </p>
            </div>
          </section>
        </>
      ) : (
        <h3
          className="err_message"
          style={{ marginTop: "7em", marginBottom: "5em" }}
        >
          No Directors data found
        </h3>
      )}
    </main>
  );
};

export default LeadershipInterviewInner;
