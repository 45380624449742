import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Homepage from "./pages/Homepage/Homepage";
import OurBusiness from "./pages/OurBusiness/OurBusiness";
import OurBrand from "./pages/OurBrand/OurBrand";
import Footer from "./components/Footer/Footer";
import Reimagined from "./pages/OurCompnay/Reimagined/Reimagined";
import OurReach from "./pages/OurCompnay/OurReach/OurReach";
import Header from "./components/Header/Header";
import AboutUs from "./pages/OurCompnay/AboutUs/AboutUs";
import Sustainability from "./pages/Sustainability/Sustainability";
import ContactUs from "./pages/ContactUs/ContactUs";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import FAQ from "./pages/FAQ/FAQ";
import PartnerWithUs from "./pages/PartnerWithUs/PartnerWithUs";
import NotFound from "./pages/NotFound/NotFound";
import ThankYou from "./pages/ThankYou/ThankYou";
import Governance from "./pages/OurCompnay/Governance/Governance";
import GovernanceOverview from "./pages/OurCompnay/Governance/GovernanceOverview";
import GovernanceDirectors from "./pages/OurCompnay/Governance/GovernanceDirectors";
import GovernanceDirectorInner from "./pages/OurCompnay/Governance/GovernanceDirectorInner";
import GovernanceComplianceReport from "./pages/OurCompnay/Governance/GovernanceComplianceReport";
import MediaReportsListing from "./pages/Newsroom/MediaReports/MediaReportsListing";
import MediaReportsOverview from "./pages/Newsroom/MediaReports/MediaReportsOverview";
import MediaReportsDirectors from "./pages/Newsroom/MediaReports/MediaReportsDirectors";
import MediaReportsDirectorInner from "./pages/Newsroom/MediaReports/MediaReportsDirectorInner";
import MediaReportsComplianceReport from "./pages/Newsroom/MediaReports/MediaReportsComplianceReport";
import LeadershipInterview from "./pages/OurCompnay/LeadershipInterview/LeadershipInterview";
import LeadershipInterviewInner from "./pages/OurCompnay/LeadershipInterviewInner/LeadershipInterviewInner";
import InvestorsListing from "./pages/Investors/InvestorsListing";
import InvestorsCategoryListing from "./pages/Investors/InvestorsCategoryListing";
import InvestorsOverview from "./pages/Investors/InvestorsOverview";
import InvestorsTable from "./pages/Investors/InvestorsTable";
import InvestorsDirectors from "./pages/Investors/InvestorsDirectors";
import InvestorsDirectorInner from "./pages/Investors/InvestorsDirectorInner";
import InvestorsComplianceReport from "./pages/Investors/InvestorsComplianceReport";
import Investor from "./pages/Investor/Investor";
import Loader from "./components/Loader/Loader";
import {
  useGetGovernanceRoutes,
  useGetGovernanceCategoryRoutes,
  useGetMediaRoutes,
} from "./hooks/useGetRoutes";
import { useGetInvestorsData } from "./hooks/useGetHomepageData";
import MediaContacts from "./pages/Newsroom/MediaContacts/MediaContacts";
import InvestorContact from "./pages/Investors/InvestorContact/InvestorContact";
import AwardsAccolads from "./pages/OurCompnay/AwardsAccolads/AwardsAccolads";
import Lifestyle from "./pages/Lifestyle/Lifestyle";
import Career from "./pages/Career/Career";
import { aboutusurl, homepageurl, investorURL } from "./helpers/constant-words";
import _ from "lodash";
import CookiePolicy from "./pages/CookiePolicy/CookiePolicy";
const mediaCompArr = [
  {
    temp: "temp_1",
    tempComponent: <MediaReportsOverview />,
  },
  {
    temp: "temp_2",
    tempComponent: <MediaReportsDirectors />,
  },
  {
    temp: "temp_3",
    tempComponent: <MediaReportsComplianceReport />,
  },
];
const governanceCompArr = [
  {
    temp: "temp_1",
    tempComponent: <GovernanceOverview />,
  },
  {
    temp: "temp_2",
    tempComponent: <GovernanceDirectors />,
  },
  {
    temp: "temp_3",
    tempComponent: <GovernanceComplianceReport />,
  },
];
const investorCompArr = [
  {
    temp: "temp_1",
    tempComponent: <InvestorsOverview />,
  },
  {
    temp: "temp_2",
    tempComponent: <InvestorsDirectors />,
  },
  {
    temp: "temp_3",
    tempComponent: <InvestorsComplianceReport />,
  },
  {
    temp: "temp_4",
    tempComponent: <InvestorsTable />,
  },
];
const Routing = () => {
  const { isMediaRoutesLoading, mediaReportsData } = useGetMediaRoutes();
  const { isGovernaceRoutesLoading, governaceData } = useGetGovernanceRoutes();
  const { isGovernaceCategoryRoutesLoading, governaceCategoryData } =
    useGetGovernanceCategoryRoutes();
  const { isInvestorsMenuLoading, investorsMenuData } = useGetInvestorsData();

  const getMediaElement = (temp) => {
    for (let i = 0; i < mediaCompArr.length; i++) {
      if (mediaCompArr[i]["temp"] === temp) {
        return mediaCompArr[i]["tempComponent"];
      }
    }
  };
  const getGovernanceElement = (temp) => {
    for (let i = 0; i < governanceCompArr.length; i++) {
      if (governanceCompArr[i]["temp"] === temp) {
        return governanceCompArr[i]["tempComponent"];
      }
    }
  };
  const getInvestorElement = (temp) => {
    for (let i = 0; i < investorCompArr.length; i++) {
      if (investorCompArr[i]["temp"] === temp) {
        return investorCompArr[i]["tempComponent"];
      }
    }
  };
  const mediaReportsRoutesList = mediaReportsData?.map(
    ({ slug, template }, i) => (
      <Route
        key={i}
        path={`/newsroom/news-and-media/${slug}`}
        element={getMediaElement(template)}
      />
    )
  );

 
  const governaceList = governaceData?.map(
    ({ slug, template, _id, investorCategory }) => (
      <Route
        key={_id}
        path={`/our-company/${investorCategory?.slug}/${slug}`}
        element={getGovernanceElement(template)}
      />
    )
  );
  const governaceCategoryList = governaceCategoryData?.map(
    ({ title, slug, _id }) => (
      <Route key={_id} path={`/our-company/${slug}`} element={<Governance />} />
    )
  );

  let newinvestorsMenuData;
  if (investorsMenuData) {
    let tempCategoryIndex = _.findIndex(investorsMenuData, (o) => {
      return o.slug === "disclosures-under-regulation-46-of-the-lodr";
    });
    let newcat = {
      _id: "6308b6961d7fb152e7de76a4",
      name: "Disclosures under Regulation 62 of the LODR",
      slug: "disclosures-under-regulation-62-of-the-lodr",
      createdAt: "2022-08-26T12:03:34.681Z",
      updatedAt: "2022-08-26T12:03:34.681Z",
      __v: 0,
      subcategories: investorsMenuData[tempCategoryIndex]?.subcategories,
    };

    newinvestorsMenuData = [...investorsMenuData, newcat];
   
  }

  const investorList = newinvestorsMenuData?.map(
    ({ slug: mainCatSlug, _id, subcategories }) =>
      subcategories.map(({ slug: subCatSlug, policies }) =>
        policies.map(({ slug: pageSlug, template }) => (
          <Route
            key={_id}
            path={`/investor/${mainCatSlug}/${subCatSlug}/${pageSlug}`}
            element={getInvestorElement(template)}
          />
        ))
      )
  );

  if (
    isMediaRoutesLoading ||
    isGovernaceRoutesLoading ||
    isInvestorsMenuLoading ||
    isGovernaceCategoryRoutesLoading
  ) {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <Routes>
        {/* vijay routes */}
        <Route path={homepageurl} element={<Homepage />} />
        <Route path={aboutusurl} element={<AboutUs />} />
        <Route path={investorURL} element={<Investor />} />
        {/* <Route path="/our-company/leaders" element={<Governance />} />
        <Route path="/our-company/corporate-governance" element={<Governance />} /> */}
        {governaceCategoryList}
       
        {governaceList}
        <Route
          path={`/our-company/${governaceData[0]?.investorCategory?.slug}/:boardOfDirectors/:boardOfDirectorsInnerPage`}
          element={<GovernanceDirectorInner />}
        />
        <Route
          path="/our-company/senior-leaders"
          element={<LeadershipInterview />}
        />
        <Route
          path="/our-company/senior-leaders/:leadershipInterviewsInner"
          element={<LeadershipInterviewInner />}
        />
        {/* media reports */}
        <Route
          path="/newsroom/news-and-media"
          element={<MediaReportsListing />}
        />
        <Route path="/newsroom/media-contact" element={<MediaContacts />} />
        {mediaReportsRoutesList}
        <Route
          path="/newsroom/news-and-media/:boardOfDirectors/:boardOfDirectorsInnerPage"
          element={<MediaReportsDirectorInner />}
        />

        <Route
          exact
          path="/investor/:categorySlug"
          element={<InvestorsCategoryListing />}
        />
        <Route
          exact
          path="/investor/:categorySlug/:subCategorySlug"
          element={<InvestorsListing />}
        />

        {/* <Route
          // path="/investor/:categorySlug/:subCategorySlug/:pageOverview"
          element={<InvestorsOverview />}
        />
        <Route
          path="/investor/:categorySlug/:subCategorySlug/:boardOfDirectors"
          element={<InvestorsDirectors />}
        />
        <Route
          // path="/investor/:categorySlug/:subCategorySlug/:complianceReport"
          element={<InvestorsComplianceReport />}
        /> */}
        {investorList}
        <Route
          path="/investor/:categorySlug/:subCategorySlug/:boardOfDirectors/:boardOfDirectorsInnerPage"
          element={<InvestorsDirectorInner />}
        />

        {/* end of vijay routes */}
        <Route exact path="/investor-contact" element={<InvestorContact />} />
        <Route exact path="/our-businesses" element={<OurBusiness />} />
        <Route exact path="/lifestyle" element={<Lifestyle />} />
        <Route exact path="/career" element={<Career />} />
        <Route exact path="/our-brand/:brandCategory" element={<OurBrand />} />
        <Route
          exact
          path="/our-company/awards-and-accolades"
          element={<AwardsAccolads />}
        />
        <Route
          exact
          path="/our-company/raymond-re-imagined"
          element={<Reimagined />}
        />
        <Route exact path="/our-company/our-reach" element={<OurReach />} />
        <Route
          exact
          path="/beyond-business/csr/:susSlug"
          element={<Sustainability />}
        />
        <Route
          exact
          path="/beyond-business/esg/:susSlug"
          element={<Sustainability />}
        />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/terms-of-use" element={<TermsAndConditions />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="cookie-policy" element={<CookiePolicy />} />
        <Route exact path="/faq" element={<FAQ />} />
        <Route exact path="/partner-with-us" element={<PartnerWithUs />} />
        <Route exact path="/thank-you" element={<ThankYou />} />
        <Route exact path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      <Footer />
    </>
  );
};

export default Routing;
