import React, { useEffect, useState } from "react";
import "../../common-styles/GovernanceOverview.scss";
import parse from "html-react-parser";
import { useQuery } from "react-query";

import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import { request } from "../../../utils/axios-utils";
import Loader from "../../../components/Loader/Loader";
import { getLastSegment, mediaImageUrl } from "../../../helpers/utils";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";
const fetchOverview = (lastSegment) => {
  return request({ url: `/media/${lastSegment}`, method: "get" });
};
const MediaReportsOverview = () => {
  const [lastSegment, setLastSegment] = useState("");
  const [overviewData, setOverviewData] = useState(null);

  const { isLoading, refetch: refetchOverview } = useQuery(
    ["overview-content", lastSegment],
    () => fetchOverview(lastSegment),
    {
      enabled: false,
      onSuccess: (data) => {
        if (data.data.data) {
          setOverviewData(data.data.data);
        }
      },
    }
  );
  useEffect(() => {
    if (lastSegment) {
      refetchOverview();
    }
  }, [lastSegment]);

  useEffect(() => {
    setLastSegment(getLastSegment(window.location.pathname));
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <main>
    <MetaDecorator metaTitle="Welcome to Raymond | Media" />
      {overviewData !== null && (
        <section className="govoversec1" data-aos="fade-in">
          <div className="my_container">
            <BreadCrumb
              linkTwoText="newsroom"
              linkTwoDisabled
              linkThreeText="news and media"
              linkThree="/newsroom/news-and-media"
              linkFourText={overviewData.name}
              linkFourDisabled
            />
            <h1 className="govover_heading">{overviewData.title}</h1>
            <p className="govover_subheading">
              {overviewData?.innerDescription
                ? parse(overviewData?.innerDescription)
                : null}
            </p>
            <div className="govover_desc">
              {overviewData?.templateData
                ? parse(overviewData?.templateData?.content)
                : null}
            </div>
            {overviewData?.templateData ? (
              overviewData?.templateData?.image ? (
                <img
                  src={mediaImageUrl + overviewData?.templateData?.image}
                  alt=""
                  className="img-fluid"
                />
              ) : null
            ) : null}
          </div>
        </section>
      )}
    </main>
  );
};

export default MediaReportsOverview;
