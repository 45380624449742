import { useQuery } from "react-query";
import { request } from "../utils/axios-utils";

const fetchAwards = () => {
  return request({ url: `/awards`, method: "get" });
};

const useGetAwardsData = () => {
  const { isLoading, data: awardsData } = useQuery("awards", fetchAwards);

  return {
    isLoading,
    awardsData,
  };
};

export default useGetAwardsData;
