import React, { useEffect, useState } from "react";
import { Data } from "./Data";
import { plusicon, minusicon, faqban } from "../../images";
import "./faq.scss";
// import AOS from "aos";
// import "aos/dist/aos.css";

const Accordion = () => {
  const [clicked, setClicked] = useState(false);
  const toggle = (index) => {
    if (clicked === index) {
      //if clicked question is already active, then close it
      return setClicked(null);
    }

    setClicked(index);
  };
  useEffect(() => {
    // AOS.init();
  }, []);
  useEffect(() => {
    const yOffset = -170;

    const element = document.getElementById(clicked);
    if (element) {
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }, [clicked]);
  return (
    <>
      <div
        className="accordionsec"
        // data-aos="fade-up"
        // data-aos-delay="600"
        // data-aos-duration="1000"
      >
        <div className="acc_container">
          {Data.map((item, index) => {
            return (
              <>
                <div className="accoedion_box" id={index}>
                  <div
                    className="acc_wrap"
                    onClick={() => toggle(index)}
                    key={index}
                  >
                    <h4 className="title">{item.question}</h4>
                    <span className="acc_span">
                      {clicked === index ? (
                        <img className="minus_icon" src={minusicon} />
                      ) : (
                        <img className="plus_icon" src={plusicon} />
                      )}
                    </span>
                  </div>
                  <div className="acc_dropdown">
                    {clicked === index ? (
                      <p
                        className="acc_p"
                        dangerouslySetInnerHTML={{ __html: item.answer }}
                      ></p>
                    ) : null}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Accordion;
