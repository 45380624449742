import React from "react";
import "./investorcontact.scss";
import { mediabannerimg } from "../../../images";
import parse from "html-react-parser";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";

const investorData = [
  {
    id: 1,
    title: "REGISTERED OFFICE",
    address:
      "Raymond Limited, Plot No.156/H.No. 2, Village Zadgaon Ratnagiri - 415 612, Maharashtra, India.",
    contact:
      '<a className="contact_link" href="tel:+912352232514"> +91 2352 232514 </a> <a className="contact_link" href="tel:+912352232513"> +91 2352 232513 </a>',
  },
  {
    id: 2,
    title: "CORPORATE OFFICE",
    address:
      "Raymond Limited, New Hind House, Narottam Morarjee Marg, Ballard Estate, Mumbai – 400 001, Maharashtra, India.",
    contact:
      '<a className="contact_link" href="tel:02240349999"> Tel No. 022 40349999 </a> | Fax No. 022 24939036/ 24925084',
  },
  {
    id: 3,
    title: "INVESTOR RELATIONS",
    address:
      "Raymond Limited, New Hind House, Narottam Morarjee Marg, Ballard Estate, Mumbai – 400 001, Maharashtra, India.",
    contact:
      '<a className="contact_link" href="tel:02240349999"> Tel No. 022 40349999 </a> | Fax No. 022 24939036/ 24925084 <br /> <a className="contact_link" href="mailto:raymond.ir@raymond.in"> raymond.ir@raymond.in </a> ',
  },
  {
    id: 4,
    title: "FOR SHAREHOLDER GRIEVANCES",
    address:
      "Nodal Officer <br /> Shri Rakesh Darji, Road No. 1, P.O. Jekegram, Thane (west) 400 606.",
    contact:
      '<a className="contact_link" href="tel:+912261528687"> +91 22 6152 8687</a>/ <a className="contact_link" href="tel:+912261528619"> 8619 </a> <a className="contact_link" href="tel:+912225382912"> +91 22 2538 2912</a>/ <a className="contact_link" href="tel:+91222541 2805"> 2541 2805 </a> <br /> <a className="contact_link" href="mailto:corp.secretarial@raymond.in"> corp.secretarial@raymond.in </a> ',
  },
  {
    id: 5,
    address:
      "Deputy Nodal Officer <br /> Smt. Priti Alkari, Road No. 1, P.O. Jekegram, Thane (west) 400 606.",
    contact:
      '<a className="contact_link" href="tel:+912261528687"> +91 22 6152 8687</a>/ <a className="contact_link" href="tel:+912261528619"> 8619 </a> <a className="contact_link" href="tel:+912225382912"> +91 22 2538 2912</a>/ <a className="contact_link" href="tel:+91222541 2805"> 2541 2805 </a> <br /> <a className="contact_link" href="mailto:corp.secretarial@raymond.in"> corp.secretarial@raymond.in </a> ',
  },
  {
    id: 6,
    address:
      "Alternatively a Counter for Service of Documents to Mumbai based Shareholders: Raymond Limited New Hind House, Narottam Morarjee Marg, Ballard Estate, Mumbai – 400 001.",
    contact:
      '<a className="contact_link" href="tel:+912266046047"> +91 22 6604 6047</a> ',
  },
  {
    id: 7,
    address:
      "Registrar and Transfer Agents <br /> Link Intime India Private Limited <br />C-101, 247 Park, L B S Marg, Vikhroli (West), Mumbai 400 083.",
    contact:
      '<a className="contact_link" href="tel:+9102249186270"> Tel : (022) 49186270</a>/ Fax : (022) 49186060 <br /> <a href="mailto:rnt.helpdesk@linkintime.co.in">rnt.helpdesk@linkintime.co.in</a>',
  },
  {
    id: 8,
    title: "FOR DEBENTUREHOLDERS",
    address:
      "Axis Trustee Services Limited Axis House, 2nd Floor, Wadia International Centre, P. B. Marg, Worli, Mumbai 400 025.<br /><br />",
    contact:
      "<strong>Name</strong>: Shri Gautam Hari Singhania<br /> <strong>Designation</strong>: Chairman and Managing Director<br /> <strong>Address</strong>: Raymond Limited, Sapphire Building, Pokhran Road No. 1, P.O. Jekegram (West) - 400 606.<br /> <strong>Contact No.</strong>: <a className='contact_link' href='tel:+912261527000'>+91 22 61527000</a><br /> <strong>Fax</strong>: +91 22 25412805<br /> <br /> <strong>Name</strong>: Shri. Amit Agarwal<br /> <strong>Designation</strong>: Chief Financial Officer<br /> <strong>Address</strong>: Raymond Limited, Sapphire Building, Pokhran Road No. 1, P.O. Jekegram (West) - 400 606.<br /> <strong>Contact No.</strong>: <a className='contact_link' href='tel:+912261527000'>+91 22 61527000</a><br /> <strong>Fax</strong>: +91 22 25412805<br /> <br /> <strong>Name</strong>: Shri Rakesh Darji<br /> <strong>Designation</strong>: Director – Secretarial & Company Secretary<br /> <strong>Address</strong>: Raymond Limited, Sapphire Building, Pokhran Road No. 1, P.O. Jekegram (West) - 400 606.<br /> <strong>Contact No.</strong>: <a className='contact_link' href='tel:+912261527000'>+91 22 61527000</a><br /> <strong>Fax</strong>: +91 22 25412805<br />",
  },
];

const InvestorContact = () => {
  return (
    <>
    <MetaDecorator metaTitle="Welcome to Raymond | Investor Contact" />
      <section className="investorcontact_sec1" data-aos="fade-in">
        <div className="my_container">
          <div className="text_container">
            <BreadCrumb
              linkTwoText="Investor"
              linkTwoDisabled
              linkThreeText="Investor Relations Contact"
              linkThreeDisabled
            />
            <h1 className="heading">Investor Relations Contact</h1>
            {/* <div className="copy_desc">
              <div>
                <strong>
                  Raymond Limited, New Hind House, Narottam Morarjee Marg,
                  Ballard Estate, Mumbai – 400 001
                </strong>
                <br /> To share a feedback or grievenace, submit a ticket below
                or write to us at <a href="mailto:consumercarecell@raymond.in">consumercarecell@raymond.in</a>. We will get in
                touch shortly.
              </div>
            </div> */}
            {/* <p className="desc">
              Are you a journalist or a media professional? Do you need to
              contact a member of our media relations team? Find our contact
              details down below.
            </p> */}
          </div>
          <img
            src={mediabannerimg}
            alt="raymond trust img"
            className="mainimg"
            width={1631}
            height={509}
          />
        </div>
      </section>

      <section
        className="investorcontact_sec2 extra_space_issue"
        data-aos="fade-up"
      >
        <div className="my_container">
          {/* <div className="text_container"> */}
          {/* <h4 className="breadcrumb">Connect With Us</h4> */}
          {/* {width > 600 ? (
              <p className="texttoright">
                Please use the following Raymond investor relations contacts for{" "}
                <br />
                inquiries from the investor only
              </p>
            ) : null} */}
          {/* <h2 className="heading">Connect For Investor Enquiries</h2> */}
          {/* {width <= 600 ? (
              <p className="texttoright">
                Please use the following Raymond investor relations contacts for{" "}
                inquiries from the investor only
              </p>
            ) : null} */}
          {/* </div> */}
          {/* <div className="row"> */}
          {/* <div className="col-md-6 col-lg-4">
              <div className="box_container">
                <img className="icon_img"  alt="location img" src={locationicon} />
                <h4 className="heading">Raymond Limited</h4>
                <p className="desc">
                  Jekegram, Pokhran Road No 1, <br /> Thane - 400 606,
                  Maharashtra, India.
                </p>
              </div>
            </div> */}
          {/* <div className="col-md-6 col-lg-4">
              <div className="box_container">
                <img className="icon_img" alt="call img" src={callicon} />
                <h4 className="heading">J Mukund (Investor Queries)</h4>
                <p className="desc">
                  <a className="number" href="tel:+919321359624">
                    +91 9321359624
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="box_container">
                <img className="icon_img" alt="mail img" src={mailicon} />
                <h4 className="heading">Write To Us On</h4>
                <p className="desc">
                  <a className="number" href="mailto:J.Mukund@raymond.in">
                    J.Mukund@raymond.in
                  </a>
                </p>
              </div>
            </div> */}
          <div className="investor_contact_new">
            {investorData.map((item, i) => (
              <>
                <div className="new_contact">
                  <h4 className="contact_heading">
                    {item.title ? parse(item.title) : null}
                  </h4>
                  <p className="contact_details">
                    {item.address ? parse(item.address) : null}
                  </p>
                  <p className="contact_details">
                    {item.contact ? parse(item.contact) : null}
                  </p>
                </div>
              </>
            ))}
          </div>
        </div>
        {/* </div> */}
      </section>
    </>
  );
};

export default InvestorContact;
