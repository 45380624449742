import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./Header.scss";
//images import
import { ReactComponent as Fbgrey } from "../../images/homepage/fb-grey.svg";
import { ReactComponent as Instagrey } from "../../images/homepage/insta-grey.svg";
import { ReactComponent as Linkgrey } from "../../images/homepage/link-grey.svg";
import { ReactComponent as Twiitergrey } from "../../images/homepage/twiiter-grey.svg";
import { ReactComponent as Youtubegrey } from "../../images/homepage/youtube-grey.svg";
import pintrst from "../../images/homepage/pintrst.svg";
import logoWhite from "../../images/general/logo-white.svg";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import HeaderNavItem from "./HeaderNavItem";
import {
  useGetInvestorsData,
  useGetMenuSusData,
  useGetOurBrandsData,
} from "../../hooks/useGetHomepageData";
import {
  
  useGetGovernanceCategoryRoutes,
  
} from "../../hooks/useGetRoutes";
import Loader from "../Loader/Loader";
import { aboutusurl, homepageurl } from "../../helpers/constant-words";
import {
  calliconheaderblack,
  raymondlogohighres,
  raymondlogosvg,
  storelocator,
} from "../../images";
import _ from "lodash";

const body = document.querySelector("body");

const Header2 = () => {
  const { isOurBrandsLoading, brandsData } = useGetOurBrandsData();
  const { isInvestorsMenuLoading, investorsMenuData } = useGetInvestorsData();
  const { isMenuSusDataLoading, susMenuData } = useGetMenuSusData();
  const { isGovernaceCategoryRoutesLoading, governaceCategoryData } = useGetGovernanceCategoryRoutes();

  const { width } = useWindowDimensions();
  const { pathname: locationPathname } = useLocation();
  const [navActiveIndex, setNavActiveIndex] = useState(null);
  const [isMenuActive, setIsMenuActive] = useState(false);

  // const brandsDataArr = brandsData?.data?.data;

  // const brandsSlugsList = brandsDataArr?.length
  //   ? brandsDataArr.map((brandData) => {
  //       return {
  //         navTitle: brandData.title,
  //         navLink: `/our-brand/${brandData.slug}`,
  //       };
  //     })
  //   : null;

  // const newBrandSlugsList = brandsDataArr?.length
  //   ? brandsDataArr.map((data) => ({
  //       dropDowntitle: data?.title,
  //       linkArr: data?.brands?.map(({ title, slug }) => ({
  //         navTitle: title,
  //         navLink: `/our-brand/${data?.slug}`,
  //       })),
  //     }))
  //   : null;

  //duplicating subcategories of disclosures-under-regulation-46-of-the-lodr in  disclosures-under-regulation-62-of-the-lodr


  // let tempCategoryIndex = _.findIndex(investorsMenuData, (o) => {
  //   return o.slug === "disclosures-under-regulation-46-of-the-lodr";
  // });

  // investorsMenuData.splice(tempCategoryIndex + 1, 0, {
  //   name: "Disclosures under Regulation 62 of the LODR",
  //   slug: "disclosures-under-regulation-62-of-the-lodr",
  //   subcategories: investorsMenuData[tempCategoryIndex].subcategories,
  // });

  // const investorsSlugsList = [
  //   ...investorsMenuData?.map(({ slug: mainCatSlug, name, subcategories }) => {
  //     return {
  //       dropDowntitle: name,
  //       linkArr: subcategories
  //         .filter(({ showInMenu }) => showInMenu === true)
  //         .map(({ title, slug, showInMenu }) => {
  //           return {
  //             navTitle: title,
  //             navLink: `/investor/${mainCatSlug}/${slug}`,
  //           };
  //         }),
  //     };
  //   }),

  //   {
  //     dropDowntitle: "Investor Contact Details",
  //     linkArr: [],
  //     dropDownLink: "/investor-contact",
  //   },
  // ];
  const oneLevelInvestorsSlugsList = [
    ...investorsMenuData?.map(({ slug: mainCatSlug, name, subcategories }) => {

      return {
        navTitle: name,
        navLink: `/investor/${mainCatSlug}`,
      };
     
    }),
    {
      navTitle: "Investor Contact Details",
      navLink: "/investor-contact",
    },
  ];

  

  const csrSlugsList = susMenuData
    ?.filter(({ category }) => category.toLowerCase() === "csr")
    .map(({ slug, title }) => ({
      navTitle: title,
      navLink: `/beyond-business/csr/${slug}`,
    }));
  const governaceCategoryList = governaceCategoryData?.length ? governaceCategoryData?.map(({ slug, title }) => ({
      navTitle: title,
      navLink: `/our-company/${slug}`,
    })) : [];

  // const esgSlugsList = susMenuData;
  // ?.filter(({ category }) => category.toLowerCase() === "esg")
  // .map(({ slug, title }) => ({
  //   navTitle: title,
  //   navLink: `/beyond-business/esg/${slug}`,
  // }));

  const navDataArr = [
    {
      mainTitle: "Our Businesses",
      mainLink: "/our-businesses",
    },
    {
      mainTitle: "Our Company",
      dropDownLevel: 1,
      dropDownList: [
        {
          navTitle: "about us",
          navLink: aboutusurl,
        },
        {
          navTitle: "Raymond Re-imagined",
          navLink: "/our-company/raymond-re-imagined",
        },
        // {
        //   navTitle: "Leaders",
        //   navLink: "/our-company/leaders",
        // },
        // {
        //   navTitle: "Senior Leaders",
        //   navLink: "/our-company/senior-leaders",
        // },
        {
          navTitle: "Awards & Accolades",
          navLink: "/our-company/awards-and-accolades",
        },
        {
          navTitle: "our reach",
          navLink: "/our-company/our-reach",
        },
       ...governaceCategoryList
       
      ],
    },
    // {
    //   mainTitle: "Our brands",
    //   dropDownLevel: 2,
    //   dropDownList: newBrandSlugsList,
    // },
    {
      mainTitle: "Beyond Business",
      dropDownLevel: 1,
      dropDownList: csrSlugsList,
      // {
      //   dropDowntitle: "ESG",
      //   linkArr: isMenuSusDataLoading ? [] : esgSlugsList,
      // },
    },
    {
      mainTitle: "investors",
      dropDownLevel: 1,
      dropDownList: oneLevelInvestorsSlugsList,
      navLink:"/investor"
    },
    {
      mainTitle: "newsroom",
      dropDownLevel: 1,
      dropDownList: [
        {
          navTitle: "News & Media",
          navLink: "/newsroom/news-and-media",
        },
        {
          navTitle: "Media Contact",
          navLink: "/newsroom/media-contact",
        },
        // {
        //   navTitle: "Career",
        //   navLink: "/career",
        // },
      ],
    },
    // {
    //   mainTitle: "FAQ's",
    //   mainLink: "/faq",
    // },
  ];

  useEffect(() => {
    body.style.overflow = "auto";
    setIsMenuActive(false);
  }, [locationPathname]);

  const handleHamburgerClick = () => {
    setIsMenuActive(!isMenuActive);
    if (!isMenuActive) {
      // Disable scroll
      body.style.overflow = "hidden";
    } else {
      // Enable scroll
      body.style.overflow = "auto";
    }
  };

  const handleNavIndex = (i) => {
    if (navActiveIndex === i) {
      setNavActiveIndex(null);
    } else {
      setNavActiveIndex(i);
    }
  };

  if (isOurBrandsLoading || isInvestorsMenuLoading || isMenuSusDataLoading || isGovernaceCategoryRoutesLoading) {
    return <Loader />;
  }

  return (
    <>
      <header className="header">
        <a className="logo_link" href={homepageurl}>
          <div className="logo_wrapper">
            <img
              src={raymondlogohighres}
              alt="Raymond logo"
              className="raymond_logo"
            />
          </div>
        </a>
        <nav className="nav_links_wrapper">
          {/* this is line 1 of navigation */}
          <ul className="nav_line1_wrapper_flex custom-li">
          <li className="nav_item1 flex_for_ticker">
              <img
                className="store_locator"
                src={storelocator}
                alt="locator icon"
              />
              <a href="https://stores.myraymond.com/" target="_blank" className="nav_link1">
              Store Locator
              </a>
            </li>
            
            <li className="nav_item1 flex_for_ticker">
              <img
                className="call_icon_ticker"
                src={calliconheaderblack}
                alt="call icon"
              />
              <a href="tel:1800222001" className="nav_link1">
                1800 222 001
              </a>
            </li>
            <li className="nav_item1">
              <NavLink
                to="/partner-with-us"
                className={({ isActive }) =>
                  isActive ? "nav_link1 active" : "nav_link1"
                }
              >
                partner with us
              </NavLink>
            </li>
            <li className="nav_item1">
              <NavLink
                to="/contact-us"
                className={({ isActive }) =>
                  isActive ? "nav_link1 active" : "nav_link1"
                }
              >
                Contact Us
              </NavLink>
            </li>
            <li className="nav_item1">
              <NavLink
                to="/career"
                className={({ isActive }) =>
                  isActive ? "nav_link1 active" : "nav_link1"
                }
              >
                Career
              </NavLink>
            </li>
          </ul>
          {/* this is line 2 of navigation */}
          <div
            className={`nav_line2_wrapper_flex_wrapper ${
              isMenuActive ? "active" : ""
            }`}
          >
            <ul className="nav_line2_wrapper_flex custom-li">
              {navDataArr.map((navData, i) => (
                <HeaderNavItem
                  navData={navData}
                  key={i}
                  arrIndex={i}
                  handleNavIndex={handleNavIndex}
                  navActiveIndex={navActiveIndex}
                />
              ))}
              {width <= 1023 && (
                <>
                  <li className="nav_item">
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "nav_link active" : "nav_link"
                      }
                      to="/partner-with-us"
                    >
                      partner with us
                    </NavLink>
                  </li>
                  <li className="nav_item">
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "nav_link active" : "nav_link"
                      }
                      to="/contact-us"
                    >
                      Contact
                    </NavLink>
                  </li>
                  <li className="nav_item">
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "nav_link active" : "nav_link"
                      }
                      to="/career"
                    >
                      Career
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
            {width <= 1023 && (
              <div className="nav_social_media_icons">
                <div className="follow_us_text">Follow Us On</div>
                <a
                  href="https://in.linkedin.com/company/raymond-limited"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Linkgrey className="nav_social_icons" />
                </a>
                <a
                  href="https://www.instagram.com/raymond_the_complete_man/?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Instagrey className="nav_social_icons" />
                </a>
                <a
                  href="https://www.facebook.com/RaymondLimited/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Fbgrey className="nav_social_icons" />
                </a>
                <a
                  href="https://twitter.com/theraymondltd?lang=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Twiitergrey className="nav_social_icons" />
                </a>
                <a
                  href="https://www.youtube.com/user/raymondlimited"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Youtubegrey className="nav_social_icons" />
                </a>
                <a
                  href="https://in.pinterest.com/Raymond_corp/?eq=raymond%20ltd&etslf=6757"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={pintrst} alt="" className="nav_social_icons" />
                </a>
              </div>
            )}
          </div>
        </nav>

        {/* mobile UI start */}
        <div
          className={`hamburger_lines ${isMenuActive ? "active" : ""}`}
          onClick={handleHamburgerClick}
        >
          <div className="line line1"></div>
          <div className="line line2"></div>
          <div className="line line3"></div>
        </div>
      </header>
    </>
  );
};

export default Header2;
//media contacts
// leaders
// investor --> business performace
//  faq
