import React, { useEffect, useState } from "react";
import "../../common-styles/GovernanceDirectorInner.scss";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import { useQuery } from "react-query";
import { request } from "../../../utils/axios-utils";
import Loader from "../../../components/Loader/Loader";
import parse from "html-react-parser";

import { useParams } from "react-router-dom";
import { convertSlugtoString } from "../../../helpers/utils";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";

const fetchDirectorsInner = (lastSegment) => {
  return request({ url: `/media/directors/${lastSegment}`, method: "get" });
};

const MediaReportsDirectorInner = () => {
  const { boardOfDirectors, boardOfDirectorsInnerPage } = useParams();
  const [directorsInnerData, setDirectorsInnerData] = useState(null);

  const { isLoading, refetch: refetchDirectorsInner } = useQuery(
    ["media-directors-inner", boardOfDirectorsInnerPage],
    () => fetchDirectorsInner(boardOfDirectorsInnerPage),
    {
      // cacheTime: 0,
      enabled: false,
      onSuccess: (data) => {
        if (data.data.data) {
          setDirectorsInnerData(data.data.data);
        }
      },
    }
  );
  useEffect(() => {
    refetchDirectorsInner();
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <main>
    <MetaDecorator metaTitle="Welcome to Raymond | Media" />
      {directorsInnerData && (
        <section className="director_inner_sec1" data-aos="fade-in">
          <div className="my_container">
            <BreadCrumb
              linkTwoText="newsroom"
              linkTwoDisabled
              linkThreeText="news and media"
              linkThree="/newsroom/news-and-media"
              linkFourText={convertSlugtoString(boardOfDirectors)}
              linkFour={`/newsroom/news-and-media/${boardOfDirectors}`}
              linkFiveText={boardOfDirectorsInnerPage}
              linkFiveDisabled
            />
            <h1 className="govover_heading">{directorsInnerData.name}</h1>

            <div className="govover_desc">
              {directorsInnerData?.description
                ? parse(directorsInnerData?.description)
                : null}
            </div>
          </div>
        </section>
      )}
    </main>
  );
};

export default MediaReportsDirectorInner;
