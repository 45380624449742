import React from "react";
import { Link } from "react-router-dom";
import { homepageurl } from "../../helpers/constant-words";
import "./BreadCrumb.scss";

const BreadCrumb = ({
  linkTwoText,
  linkTwoDisabled,
  linkThreeText,
  linkThree,
  linkThreeDisabled,
  linkFourText,
  linkFour,
  linkFourDisabled,
  linkFiveText,
  linkFive,
  linkFiveDisabled,
  linkSixText,
  linkSixDisabled,
}) => {
  return (
    <div className="breadcrumb_content_wrapper">
      <Link to={homepageurl} className="breadcrumb_text">
        home
      </Link>
      {linkTwoText && (
        <Link
          to={homepageurl}
          className={`breadcrumb_text ${linkTwoDisabled ? "disabled" : ""}`}
        >
          &nbsp;|&nbsp;{linkTwoText}
        </Link>
      )}
      {linkThreeText && (
        <Link
          to={`${linkThree ? linkThree : { homepageurl }}`}
          className={`breadcrumb_text ${linkThreeDisabled ? "disabled" : ""}`}
        >
          &nbsp;|&nbsp;{linkThreeText}
        </Link>
      )}
      {linkFourText && (
        <Link
          to={linkFour ? linkFour : ""}
          className={`breadcrumb_text ${linkFourDisabled ? "disabled" : ""}`}
        >
          &nbsp;|&nbsp;{linkFourText}
        </Link>
      )}
      {linkFiveText && (
        <Link
          to={linkFive ? linkFive : ""}
          className={`breadcrumb_text ${linkFiveDisabled ? "disabled" : ""}`}
        >
          &nbsp;|&nbsp;{linkFiveText}
        </Link>
      )}
      {linkSixText && (
        <Link
          to={homepageurl}
          className={`breadcrumb_text ${linkSixDisabled ? "disabled" : ""}`}
        >
          &nbsp;|&nbsp;{linkSixText}
        </Link>
      )}
    </div>
  );
};

export default BreadCrumb;
