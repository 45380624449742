import React, { useState } from "react";
import {
  ashishagrawala,
  bestsellingprojectoftheyeargcotenxhabitat,
  boilerindia2020trophy,
  careerban,
  careerban2,
  careerban3,
  careerbanner2,
  credaimchiexcellenceincustinfo,
  diversityallyaward2022,
  goldenpeacockaward,
  gptwpicture,
  harshanaik,
  kunaltoshkani,
  lifeatraymond1,
  playicon,
  quoteicon,
  sameerpandey,
  selectdropdownarrow,
  siddhantagarawal,
  thaward2,
  thaward3,
  younghrprofessionaloftheyear2022,
} from "../../images";
import Accordion from "./Accordion";
import "./career.scss";
//swiper imports
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import LeftArrowButton from "../../components/LeftArrowButton/LeftArrowButton";
import RightArrowButton from "../../components/RightArrowButton/RightArrowButton";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import FsLightbox from "fslightbox-react";
import { Fade } from "react-awesome-reveal";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import parse from "html-react-parser";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const bannerArr = [
  {
    id: 1,
    type: "img",
    img: careerban,
  },
  {
    id: 2,
    type: "img",
    img: careerban2,
  },
  {
    id: 3,
    type: "img",
    img: careerban3,
  },
];

const lifeAtRaymondArr = [
  {
    id: 1,
    img: lifeatraymond1,
    title: "Shakti",
    desc: "As the name suggests, it’s the strength and energy behind the power of the Trinity. Being the Universal Principle of Energy that manifests in all roles, she symbolises power and protection, warrior and defender, action and intuition, simultaneously. To create self-leadership amidst women, we intend to kickstart this journey by showcasing the multicoloured lives and multifaceted success stories of women who have created an impact in the world despite personal and professional odds.",
  },
  {
    id: 2,
    img: careerbanner2,
    title: "RED Day",
    desc: "Every year, 12th  December is celebrated as Retail Employees&#39; Day (RED), to thank them for their relentless efforts. With over 2 million people participating, this thoughtful initiative conceptualized by Trust for Retailers &amp; Retail Associates of India (TRRAIN) is supported by Retailers Association of India. <br /><br />At Raymond, we Retail Employees’ Day, a day is synonymous to a celebration for the retail teams across our 1500+ stores. <br /><br />On this day, every single year, we identify unique opportunities to connect retail employees and thank them for being associated with us on this journey that has been integral to delivering the Raymond brand promise. The day is planned with a series of events and activities to connect with, engage and express our gratitude towards our frontline sales team, for all the efforts they make every single day to delight consumers. The day is celebrated with much grandeur and gratitude across all cities, towns and across formats.",
  },
];

const weAreRaymondArr = [
  {
    id: 1,
    img: ashishagrawala,
    title: "<strong>Ashish Aggarwal</strong>, Corporate",
    desg: "General Manager, Mergers & Acquisitions",
    desc: "I have been associated with Raymond for over 17 amazing years. My journey began as a Management Trainee in the year 2004, and over the years I have worked in multiple roles in Business Accounts, Corporate Accounts, Corporate Taxation, and Corporate FP&A, eventually heading the Corporate M&A Function as the General Manager. These roles have honed my expertise and helped progress my career seamlessly. The work culture at Raymond is nurturing and supportive, and it’s a workplace where employees can access challenging opportunities to craft a successful career path.",
  },
  {
    id: 2,
    img: sameerpandey,
    title: "<strong>Sameer Pandey</strong>, JK Files",
    desg: "Senior Manager, Plant",
    desc: "My journey at Raymond started 12 years ago, and from the beginning I have been given opportunities to learn and grow that have helped me define my career.  The leadership programs aided my growth with immense opportunities to interact with leaders, not just within the company, but also from the industry. The multi-faceted exposure that one gets while being associated with Raymond is immensely enriching.",
  },
  {
    id: 3,
    img: siddhantagarawal,
    title: "<strong>Siddhant Aggarwal</strong>, RCCL",
    desc: "I joined Raymond in 2019 as a Management Trainee in Sales for Raymond Consumer Care Limited. During the 3 years at RCCL, I have had the opportunity to contribute in 3 different roles, which have empowered me to gain in-depth knowledge and expertise take my career to the next level. Raymond nurtures in-house talent that facilitates growth of employees in tandem with the business, perfectly justifying its core values of trust, quality and excellence.",
  },
  {
    id: 4,
    img: kunaltoshkani,
    title: "<strong>Kunal Toshkani</strong>, Lifestyle Business",
    desc: "I am proud to say that I have been part of the team at Raymond wherein we developed the world’s finest fabric – the Super 250s. The project gave me the opportunity to work with some of the best minds, and though our team wasn’t extremely experienced then, our collaborated efforts, along with the immense confidence and motivation from the leadership team, helped us succeed in delivering the project in record time. At Raymond, we are encouraged to push our limits, take risks and explore, so that we can grow to be the best versions of ourselves. ",
  },
  {
    id: 5,
    img: harshanaik,
    title: "<strong>Harsha Naik</strong>, Real Estate",
    desc: "I am as excited to be a part of Raymond and have been a part of the Raymond Realty team since its inception in 2018. With each passing day there has been an opportunity to do something innovative that contributes positively to people’s lives and fulfilling their dreams of owning a home. Moreover, I like the freedom offered in terms of working on initiatives sharing new ideas, expressing honest opinions. It drives me to deliver my best. At Raymond, there is an empathy towards gender equality and I can proudly say that the organisation ensures freedom to all of its employees. For me, that’s what makes Raymond a great workplace.",
  },
];

const awardsDataArr = [
  {
    id: 1,
    tabName: "Realty",
    awards: [
      {
        img: bestsellingprojectoftheyeargcotenxhabitat,
        title: "Best Selling Project of the Year GÇô Ten X Habitat",
      },
      {
        img: credaimchiexcellenceincustinfo,
        title: "CREDAI MCHI - Excellence in Cust Info",
      },
    ],
  },
  {
    id: 2,
    tabName: "Denim Business",
    awards: [
      {
        img: boilerindia2020trophy,
        title: "Boiler India 2020 Trophy",
      },
      {
        img: goldenpeacockaward,
        title: "Golden Peacock Award",
      },
    ],
  },
  {
    id: 3,
    tabName: "Engineering",
    awards: [
      {
        img: thaward2,
        title: "T&H Award Photo 2",
      },
      {
        img: thaward3,
        title: "T&H Award Photo 3",
      },
    ],
  },
  {
    id: 4,
    tabName: "Consumer Care",
    awards: [
      {
        img: diversityallyaward2022,
        title: "Diversity Ally Award 2022",
      },
      {
        img: younghrprofessionaloftheyear2022,
        title: "Young HR PRofessional of the year 2022",
      },
    ],
  },
];

const Career = () => {
  const [awardsIndex, setAwardsIndex] = useState(0);
  const { width } = useWindowDimensions();
  const [toggler, setToggler] = useState(false);
  const [vidLink, setVidLink] = useState("");

  const handleAwardTabChange = (e) => {
    const awardIndexId = e.target.selectedOptions[0].dataset.awardindexid;
    setAwardsIndex(awardIndexId);
  };

  const handleImageWrapperClick = (videoLink) => {
    setToggler(!toggler);
    setVidLink(videoLink);
  };

  const bannerList = bannerArr.map((item, i) => (
    <>
      <SwiperSlide key={i}>
        <div className="banner_box">
          {item.type === "img" ? (
            <img
              src={item.img}
              alt="raymond careers img"
              className="mainimg"
              loading="lazy"
              width={1631}
              height={509}
            />
          ) : (
            <div className="video_wrapper_div">
              <img
                src={item.img}
                alt="raymond video gallery img"
                loading="lazy"
                className="mainimg"
              />
              <img
                src={playicon}
                alt="raymond playicon img"
                loading="lazy"
                className="playicon"
                onClick={() => handleImageWrapperClick(item.link)}
              />
            </div>
          )}
        </div>
      </SwiperSlide>
    </>
  ));

  const lifeAtRaymondList = lifeAtRaymondArr.map((item, i) => (
    <SwiperSlide key={i}>
      {/* <Link to={`/our-brand/${awardsData.slug}`} state={{ boxId: _id }}> */}
      <div className="lifeatraymond_box">
        <div className="row">
          <div className="col-lg-6">
            <div className="image_wrapper">
              <img
                src={item.img}
                alt="life at raymond img"
                className="lifeatraymond_img"
                loading="lazy"
                // width={811}
                // height={540}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="text_container text_container_with_background">
              <div className="title">{item.title}</div>
              <p className="section_desc">{parse(item.desc)}</p>
            </div>
          </div>
        </div>
      </div>
      {/* </Link> */}
    </SwiperSlide>
  ));

  const weAreRaymondList = weAreRaymondArr.map((item, i) => (
    <SwiperSlide key={i}>
      {/* <Link to={`/our-brand/${awardsData.slug}`} state={{ boxId: _id }}> */}
      <div className="we_are_box">
        <img src={quoteicon} alt="quote icon" className="quote_icon" />
        <div className="image_wrapper">
          <img
            src={item.img}
            alt="profile img"
            className="we_are_profile_img"
            loading="lazy"
            width={200}
            height={200}
          />
        </div>
        <div className="text_container text_container_with_background">
        <div
            className="we_are_designation"
            dangerouslySetInnerHTML={{ __html: item.title }}
          ></div>
          {/* <div className="we_are_designation">{item.desg}</div> */}
          <p className="we_are_desc">{item.desc}</p>
        </div>
      </div>
      {/* </Link> */}
    </SwiperSlide>
  ));

  const awardsTabList = awardsDataArr?.map(({ tabName }, i) => (
    <span
      key={i}
      onClick={() => setAwardsIndex(i)}
      className={`awards_tab ${parseInt(awardsIndex) === i ? "active" : ""}`}
    >
      {tabName}
    </span>
  ));

  const mbAwardsTabList = awardsDataArr?.map(({ tabName }, i) => (
    <option value={tabName} key={i} data-awardindexid={i}>
      {tabName}
    </option>
  ));

  const awardsSwiperList = awardsDataArr?.map((awardsData, i) => (
    <Swiper
      className={`awards_swiper ${parseInt(awardsIndex) === i ? "active" : ""}`}
      key={i}
      // loop={awardsData.awards.length > 3 ? true : false}
      modules={[Navigation]}
      navigation={{
        prevEl: `.career_sec6 .left_btn_${i}`,
        nextEl: `.career_sec6 .navigation_wrapper_next_${i}`,
      }}
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2.3,
        },
        1194: {
          slidesPerView: 3,
        },
        1921: {
          slidesPerView: 3.4,
          spaceBetween: 0,
        },
      }}
    >
      {awardsData.awards.map((item, i) => (
        <SwiperSlide key={i}>
          {/* <Link to={`/our-brand/${awardsData.slug}`} state={{ boxId: _id }}> */}
          <div className="awards_box">
            <img
              src={item.img}
              alt="award img"
              className="awardimg"
              loading="lazy"
              width={366}
              height={361}
            />
            {/* <HoverRightArrow className="brand_hover_right_arrow" /> */}
          </div>
          <p className="section_desc">{item.title}</p>
          {/* </Link> */}
        </SwiperSlide>
      ))}
    </Swiper>
  ));

  return (
    <>
    <MetaDecorator metaTitle="Welcome to Raymond | Career" />
      <section className="career_sec1" data-aos="fade-in">
        <div className="my_container">
          <div className="text_container">
            <BreadCrumb linkTwoText="Career" linkTwoDisabled />
            <h1 className="heading">Career</h1>
            <p className="desc">
              Be your best self every day in a work culture that helps drive
              innovation in everything you do. Welcoming fresh talent with
              proactive solutions and transformational ideas to continue the
              legacy of the veteran group. Every employee is a part of The
              Raymond family and we ought to make them feel like their own
              company for hands-on responsibility and their comfort.{" "}
            </p>
          </div>
          <Swiper
            className="banner_swiper"
            modules={[Autoplay, Pagination]}
            pagination={{
              el: ".my-custom-pagination-div",
              clickable: true,
              renderBullet: (index, className) => {
                return '<span class="' + className + '">' + "" + "</span>";
              },
            }}
            autoplay={{
              delay: 6000,
              disableOnInteraction: false,
            }}
            loop={true}
            spaceBetween={20}
          >
            {bannerList}
          </Swiper>
          <div class="my-custom-pagination-div" />
        </div>
      </section>

      <section className="career_sec2 extra_space_issue" data-aos="fade-up">
        <div className="my_container">
          <div className="text_container">
            <h2 className="sub_heading text-center">Talent Acquisition</h2>
          </div>
          <Accordion />
        </div>
      </section>

      <section className="career_sec3" data-aos="fade-up">
        <div className="my_container">
          <div className="text_container">
            <h3 className="sub_heading text-center">Great Place To Work</h3>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="text_container">
                <p className="section_desc desc">
                  Embarking a significant milestone in our journey towards
                  success and excellence, Raymond and its group entities-
                  Raymond Limited, Raymond Apparel Limited, & Raymond Luxury
                  Cotton Limited have been certified as ‘Great Place To Work’
                  organisations in India for two consecutive times in a row.
                  Recently, Raymond Ltd and ColorPlus Ltd have acquired the
                  certification too.
                  <br />
                  <br />
                  We are extremely proud to be certified.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="image_container">
                <div className="zoomout">
                  <img className="career_img" src={gptwpicture} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="career_sec4" data-aos="fade-up">
        <div className="my_container">
          <div className="text_container text_container_margin">
            <div className="row">
              <div className="col-lg-6">
                <h3 className="sub_heading">Life At Raymond</h3>
              </div>
              <div className="col-lg-6">
                <p className="section_desc margin_desc">
                  At Raymond, we believe in the power of possibilities. A
                  culture that values your ideas and entrepreneurial zeal while
                  allowing your innovative spirit to soar and make a difference
                  in the world through all our products and offerings. We're a
                  group of specialists dedicated to attaining excellence in all
                  we do!
                </p>
              </div>
            </div>
          </div>
          <div className="swiper_container_wrapper">
            <Swiper
              modules={[Navigation]}
              slidesPerView={1}
              spaceBetween={80}
              navigation={{
                prevEl: ".swiper_container_wrapper .left_btn",
                nextEl: ".swiper_container_wrapper .right_btn",
              }}
              autoHeight={true}
            >
              {lifeAtRaymondList}
            </Swiper>
            <div className="navigation_wrapper">
              <div className="left_btn">
                <LeftArrowButton normalCta ctaText="previous" />
              </div>
              <div className="right_btn">
                <RightArrowButton normalCta ctaText="next" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="career_sec5" data-aos="fade-up">
        <div className="my_container">
          <div className="text_container">
            <div className="row">
              <div className="col-lg-6">
                <h3 className="sub_heading">Employee Testimonials</h3>
              </div>
              <div className="col-lg-6">
                <p className="section_desc margin_desc">
                  Hear it directly from our employees about what it feels like
                  to be a part of The Raymond Group.
                </p>
              </div>
            </div>
          </div>
          <div className="we_are_swiper_container_wrapper">
            <Swiper
              modules={[Navigation]}
              autoHeight={true} //enable auto height
              // loop={true}
              spaceBetween={20}
              navigation={{
                prevEl: ".we_are_swiper_container_wrapper .left_btn",
                nextEl: ".we_are_swiper_container_wrapper .right_btn",
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                992: {
                  slidesPerView: 2,
                },
              }}
            >
              {weAreRaymondList}
            </Swiper>
            <div className="navigation_wrapper">
              <div className="left_btn">
                <LeftArrowButton normalCta ctaText="previous" />
              </div>
              <div className="right_btn">
                <RightArrowButton normalCta ctaText="next" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="career_sec6" data-aos="fade-up">
        <h2 className="section_title">Awards And Accolades</h2>
        {width <= 767 ? (
          <div className="mb_award_tabs_dropdown_wrapper">
            <select
              onChange={handleAwardTabChange}
              className="mb_awards_dropdown"
            >
              {mbAwardsTabList}
            </select>
            <img
              src={selectdropdownarrow}
              alt="dropdown arrow"
              loading="lazy"
              className="selectdropdownarrow"
            />
          </div>
        ) : (
          <div className="awards_tabs_wrapper">{awardsTabList}</div>
        )}
        <div className="awards_swiper_wrapper">
          {awardsSwiperList}

          {width <= 767
            ? awardsDataArr?.map(
                (awardsData, i) =>
                  awardsData?.awards?.length > 1 && (
                    <div
                      className={`navigation_wrapper mb_navigation_wrapper ${
                        parseInt(awardsIndex) === i ? "active" : ""
                      }`}
                      key={i}
                    >
                      <div className={`left_btn left_btn_${i}`}>
                        <LeftArrowButton
                          normalCta
                          ctaText="previous"
                          ctaWhite
                        />
                      </div>
                      <div className={`right_btn navigation_wrapper_next_${i}`}>
                        <RightArrowButton normalCta ctaText="next" ctaWhite />
                      </div>
                    </div>
                  )
              )
            : awardsDataArr?.map(
                (awardsData, i) =>
                  awardsData.awards?.length > 3 && (
                    <div
                      className={`navigation_wrapper navigation_wrapper_next navigation_wrapper_next_${i} ${
                        parseInt(awardsIndex) === i ? "active" : ""
                      }`}
                      key={i}
                    >
                      <RightArrowButton normalCta ctaText="next" ctaWhite />
                    </div>
                  )
              )}
        </div>
      </section> */}

      <FsLightbox toggler={toggler} sources={[vidLink]} />
    </>
  );
};

export default Career;
